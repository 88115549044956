import { type Field, useWatch } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { FormSelect, Checkbox, FieldErrors, Label } from '@sevenrooms/core/ui-kit/form'
import { CheckboxAlignedBox, HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Anchor, Text } from '@sevenrooms/core/ui-kit/typography'
import { useDurationOptions } from '@sevenrooms/mgr-core/hooks/useDurationOptions'
import { DurationWidgetRequired } from '../../DurationWidgetRequired'
import { GuestDurationPickerLocales } from '../GuestDurationPicker.locales'
import { GuestDurationPickerTestId } from '../GuestDurationPicker.testIds'
import type { GuestDurationPickerForm } from '../GuestDurationPicker.zod'

export interface GuestDurationPickerFieldProps {
  field: Field<GuestDurationPickerForm>
}

export function GuestDurationPickerFields({ field }: GuestDurationPickerFieldProps) {
  const { formatMessage } = useLocales()
  const durationOptions = useDurationOptions()

  const guestMustSpecifyDuration = useWatch(field.prop('guestMustSpecifyDuration'))

  const tooltip = (
    <VStack spacing="s" data-test={GuestDurationPickerTestId.specifyDurationTooltip}>
      <Text color="lightFont" fontWeight="bold">
        {formatMessage(GuestDurationPickerLocales.specifyDurationLabel)}
      </Text>
      <Text color="lightFont">
        {formatMessage(GuestDurationPickerLocales.specifyDurationTooltip, {
          a: (chunks: string[]) => (
            <Anchor href="https://help.sevenrooms.com/hc/en-us/articles/360032170091-Using-Durations-with-the-Reservation-Widget">
              {chunks}
            </Anchor>
          ),
        })}
      </Text>
    </VStack>
  )

  return (
    <VStack spacing="m">
      <Checkbox
        data-test={GuestDurationPickerTestId.specifyDurationCheckbox}
        field={field.prop('guestMustSpecifyDuration')}
        description={formatMessage(GuestDurationPickerLocales.specifyDurationSubLabel)}
        info={tooltip}
      >
        {formatMessage(GuestDurationPickerLocales.specifyDurationLabel)}
      </Checkbox>
      {guestMustSpecifyDuration && (
        <>
          <CheckboxAlignedBox>
            <HStack spacing="s">
              <Label primary={formatMessage(GuestDurationPickerLocales.durationMin)}>
                <FormSelect
                  withEmpty
                  field={field.prop('durationMin')}
                  options={durationOptions}
                  searchable={false}
                  data-test={GuestDurationPickerTestId.durationMin}
                  hideErrorMessage
                />
              </Label>
              <Label primary={formatMessage(GuestDurationPickerLocales.durationMax)}>
                <FormSelect
                  withEmpty
                  field={field.prop('durationMax')}
                  options={durationOptions}
                  searchable={false}
                  data-test={GuestDurationPickerTestId.durationMax}
                  hideErrorMessage
                />
              </Label>
            </HStack>
            <FieldErrors data-test={GuestDurationPickerTestId.errors} field={field} />
          </CheckboxAlignedBox>
          <DurationWidgetRequired />
        </>
      )}
    </VStack>
  )
}
