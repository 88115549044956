// If any changes are made here, please also update:
// * TheForkSeatingArea in bookingaccessbase.py

import { objectToOptions } from '@sevenrooms/core/ui-kit/core'

export const theForkSeatingOptionsDisplay = {
  INSIDE: 'Inside',
  OUTSIDE: 'Outside',
  TERRACE: 'Terrace',
  ROOFTOP: 'Rooftop',
  PATIO: 'Patio',
  GARDEN: 'Garden',
  BAR: 'Bar',
  CHEFS_TABLE: "Chef's Table",
  CHILDRENS_AREA: "Children's Area",
  PRIVATE_DINING_ROOM: 'Private Dining Room',
  UPSTAIRS: 'Upstairs',
  DOWNSTAIRS: 'Downstairs',
  BISTRO: 'Bistro',
  LOUNGE: 'Lounge',
  WINTER_GARDEN: 'Winter Garden',
  SEASIDE: 'Seaside',
} as const

export type TheForkSeatingOptions = typeof theForkSeatingOptionsDisplay
export type TheForkSeatingAreas = keyof typeof theForkSeatingOptionsDisplay
export const theForkSeatingOptions = objectToOptions(theForkSeatingOptionsDisplay)

// If any changes are made here, please also update:
// * GoogleReserveSeatingArea in bookingaccessbase.py
export const googleReserveSeatingOptionsDisplay = {
  NOT_USING: 'No Label / Not Using',
  BAR: 'Bar',
  COUNTER: 'Counter',
  DINING_ROOM: 'Dining Room',
  GARDEN: 'Garden',
  HIGH_TOP: 'High-Top',
  INDOOR: 'Indoor',
  MAIN_DINING_ROOM: 'Main Dining Room',
  OUTDOOR: 'Outdoor',
  STANDARD: 'Standard',
  TERRACE: 'Terrace',
  AFTERNOON_TEA: 'Afternoon Tea',
  BAR_SEATING: 'Bar Seating',
  BEACH: 'Beach',
  BISTRO: 'Bistro',
  BUFFET: 'Buffet',
  CHEFS_TABLE: "Chef's Table",
  CHEFS_COUNTER: "Chef's Counter",
  COCKTAIL_BAR: 'Cocktail Bar',
  COUNTER_SEATING: 'Counter Seating',
  COUNTER_SEATS: 'Counter Seats',
  DINING_TABLE: 'Dining Table',
  DOG_FRIENDLY: 'Dog-Friendly',
  DOWNSTAIRS: 'Downstairs',
  DRINKS_TABLE: 'Drinks Table',
  FIRST_FLOOR: 'First Floor',
  GROUND_FLOOR: 'Ground Floor',
  INDOOR_DINING: 'Indoor Dining',
  INDOOR_RESERVATION: 'Indoor Reservation',
  INDOOR_SEATING: 'Indoor Seating',
  INDOOR_TABLE: 'Indoor Table',
  INDOOR_TABLES: 'Indoor Tables',
  KITCHEN_COUNTER: 'Kitchen Counter',
  LOUNGE: 'Lounge',
  MAIN_DINING: 'Main Dining',
  NON_SMOKING_SECTION: 'Non-Smoking Section',
  OUTDOOR_CABIN: 'Outdoor Cabin',
  OUTDOOR_DINING: 'Outdoor Dining',
  OUTDOOR_HEATED: 'Outdoor Heated',
  OUTDOOR_NOT_HEATED: 'Outdoor Not Heated',
  OUTDOOR_PATIO: 'Outdoor Patio',
  OUTDOOR_SEATING: 'Outdoor Seating',
  OUTDOOR_TERRACE: 'Outdoor Terrace',
  PATIO: 'Patio',
  POOLSIDE: 'Poolside',
  PRE_THEATRE: 'Pre-Theatre',
  PRIVATE_DINING_ROOM: 'Private Dining Room',
  RESTAURANT: 'Restaurant',
  ROOFTOP: 'Rooftop',
  SALLE_INTERIEURE: 'Salle Intérieure',
  SALON: 'Salón',
  SALONES: 'Salónes',
  SECOND_FLOOR: 'Second Floor',
  SMOKING_SECTION: 'Smoking Section',
  SUSHI_BAR: 'Sushi Bar',
  TERRAZA: 'Terraza',
  TERRAZZA: 'Terrazza',
  UPSTAIRS: 'Upstairs',
  WINDOW_COUNTER: 'Window Counter',
  WINDOW_HIGH_TOP: 'Window High Top',
} as const

export type GoogleReserveSeatingOptions = typeof googleReserveSeatingOptionsDisplay
export type GoogleReserveSeatingAreas = keyof typeof googleReserveSeatingOptionsDisplay
export const googleReserveSeatingOptions = objectToOptions(googleReserveSeatingOptionsDisplay)
