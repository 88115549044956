import { useMemo } from 'react'
import type { ShiftCategory } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { Report, ReportLine, ReportPart } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { accessRulesMessages } from '../../../AccessRules.locales'
import { DefaultSettingsReportSection, useAccessRuleContext } from '../../shared'
import { ScheduleLocales } from '../Schedule.locales'

interface DefaultScheduleProps {
  shiftCategories: ShiftCategory[]
}

export function DefaultSchedule({ shiftCategories }: DefaultScheduleProps) {
  const { formatMessage } = useLocales()
  const { shifts } = useAccessRuleContext()

  const displayedShifts = useMemo(
    () => shifts.filter(shift => shift.shiftCategory && shift.startTime && shift.endTime && shiftCategories.includes(shift.shiftCategory)),
    [shiftCategories, shifts]
  )

  return (
    <DefaultSettingsReportSection>
      <Report>
        <ReportPart caption={[formatMessage(accessRulesMessages.shift), formatMessage(ScheduleLocales.timeRange)]}>
          {displayedShifts.map(shift => (
            <ReportLine key={shift.id} name={shift.name}>
              <Text>
                {shift.startTime?.formatSTime()} - {shift.endTime?.formatSTime()}
              </Text>
            </ReportLine>
          ))}
        </ReportPart>
      </Report>
    </DefaultSettingsReportSection>
  )
}
