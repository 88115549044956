import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Modal, ModalActions, ModalBody, ModalFooter, ModalHeader, ModalTitle, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { ScheduleLocales } from '../Schedule.locales'
import type { PropsWithChildren } from 'react'

interface ScheduleSeriesModalProps {
  onClose: () => void
}

export function ScheduleSeriesModal({ children, onClose }: PropsWithChildren<ScheduleSeriesModalProps>) {
  const { formatMessage } = useLocales()

  return (
    <Modal ariaLabel="Possible conflict with overrides" minWidth="748px">
      <ModalHeader pb="none" onClose={() => onClose()}>
        <ModalTitle title={formatMessage(ScheduleLocales.seriesModalTitle)} />
      </ModalHeader>
      <ModalBody>
        <VStack spacing="m" pt="m">
          <Text>{formatMessage(ScheduleLocales.seriesModalBody)}</Text>
          {children}
        </VStack>
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <Button variant="primary" onClick={() => onClose()} data-test="overrides-modal-done">
            {formatMessage(commonMessages.done)}
          </Button>
        </ModalActions>
      </ModalFooter>
    </Modal>
  )
}
