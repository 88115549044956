import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { ReportLine, ReportPart } from '@sevenrooms/core/ui-kit/layout'
import { Tag } from '@sevenrooms/core/ui-kit/typography'
import { Box } from '@sevenrooms/react-components/components/Box'
import { ReservationCoverLimitTestId } from '../../ReservationCoverLimit/ReservationCoverLimit.testIds'
import { getTagDisplayName, useAccessRuleContext, getTagBackgroundColor } from '../../shared'
import { ReservationTagsLocales } from '../ReservationTags.locales'
import type { AccessRuleReportProps } from '../../shared/AccessRuleReportProps'

export function ReservationTagsReport({ accessRule, position }: AccessRuleReportProps) {
  const { tagGroups } = useAccessRuleContext()
  const { formatMessage } = useLocales()

  const { reservationTags } = accessRule

  return (
    <ReportPart caption={formatMessage(ReservationTagsLocales.title)} data-test={ReservationCoverLimitTestId.report} position={position}>
      <ReportLine name={formatMessage(ReservationTagsLocales.title)}>
        <Box display="flex" maxWidth="400px" flexWrap="wrap" pt={1} pb={1}>
          {reservationTags.length > 0
            ? reservationTags.map(tag => (
                <Tag key={`${tag.categoryId}-${tag.id}`} backgroundColor={getTagBackgroundColor(tag, tagGroups) ?? 'white'}>
                  {getTagDisplayName(tag, tagGroups)}
                </Tag>
              ))
            : formatMessage(commonMessages.none)}
        </Box>
      </ReportLine>
    </ReportPart>
  )
}
