import type { Policy, TaxRate } from '@sevenrooms/core/domain'
import { commonMessages, type FormatMessage, useLocales } from '@sevenrooms/core/locales'
import { TimeLocale, TimeOnly } from '@sevenrooms/core/timepiece'
import { ReportLine, ReportPart, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { useUpgradesSummary } from '../../GuestFacing/components/useUpgradesSummary'
import { useAccessRuleContext } from '../../shared'
import { IndentedReportLine } from '../../shared/IndentedReportLine'
import { ShiftSettingsReportLink } from '../../shared/ShiftSettingsReportLink'
import { PaymentPolicyLocales } from '../PaymentPolicy.locales'
import { PaymentPolicyTestId } from '../PaymentPolicy.testIds'
import { DefaultPaymentPolicy } from './DefaultPaymentPolicy'
import { getBundledUpgradesInfo } from './PaymentPolicyInfo'
import type { AccessRuleReportProps } from '../../shared/AccessRuleReportProps'
import type { PaymentPolicyForm } from '../PaymentPolicy.zod'

export function PaymentPolicyReport({ accessRule, position }: AccessRuleReportProps) {
  const { formatMessage } = useLocales()
  const { venueCurrencyCode } = useAppContext()
  const { defaultServiceCharge, defaultGratuity } = useAppContext().venueSettings
  const { policies, accessRule: rawRule, taxRates } = useAccessRuleContext()

  const { paymentPolicy, allowChannelsWithoutCCHolds } = accessRule
  const upgradesSummary = useUpgradesSummary(accessRule.paymentPolicy.bundledUpgrades)

  if (rawRule?.useShiftPaymentAndPolicy) {
    return (
      <ReportPart caption={formatMessage(PaymentPolicyLocales.title)} data-test={PaymentPolicyTestId.report}>
        <ReportLine name={formatMessage(PaymentPolicyLocales.requirePayment)}>
          <ShiftSettingsReportLink title={formatMessage(PaymentPolicyLocales.shiftSettingsPaymentPolicy)}>
            <DefaultPaymentPolicy />
          </ShiftSettingsReportLink>
        </ReportLine>
        <ReportLine name={formatMessage(PaymentPolicyLocales.bundledUpgrades)}>
          <Text>{getBundledUpgradesInfo(formatMessage, paymentPolicy.bundledUpgrades)}</Text>
        </ReportLine>
        <ReportLine name={formatMessage(PaymentPolicyLocales.bookOnUnsupportedLabel)}>
          {allowChannelsWithoutCCHolds ? formatMessage(commonMessages.yes) : formatMessage(commonMessages.no)}
        </ReportLine>
      </ReportPart>
    )
  }

  const requiredPaymentReport = buildRequiredPaymentReport(
    paymentPolicy,
    formatMessage,
    venueCurrencyCode,
    defaultServiceCharge,
    defaultGratuity,
    taxRates
  )
  const additionalSettingsReport = buildAdditionalSettingsReport(paymentPolicy, allowChannelsWithoutCCHolds, formatMessage)
  const cancellationReport = buildCancellationReport(paymentPolicy, formatMessage, venueCurrencyCode)
  const policiesReport = buildPoliciesReport(paymentPolicy, formatMessage, policies)

  return (
    <ReportPart caption={formatMessage(PaymentPolicyLocales.title)} data-test={PaymentPolicyTestId.report} position={position}>
      {requiredPaymentReport.map(({ title, content, indent }) =>
        indent ? (
          <IndentedReportLine key={title} name={title}>
            <Text>{content}</Text>
          </IndentedReportLine>
        ) : (
          <ReportLine key={title} name={title}>
            <Text>{content}</Text>
          </ReportLine>
        )
      )}
      {upgradesSummary && (
        <ReportLine name={formatMessage(PaymentPolicyLocales.bundledUpgrades)}>
          <VStack spacing="s">
            {upgradesSummary.split('\n\n').map(group => (
              <VStack key={group}>
                {group.split('\n').map(line => (
                  <Text key={line}>{line}</Text>
                ))}
              </VStack>
            ))}
          </VStack>
        </ReportLine>
      )}
      {additionalSettingsReport.map(({ title, content, indent }) =>
        indent ? (
          <IndentedReportLine key={title} name={title}>
            <Text>{content}</Text>
          </IndentedReportLine>
        ) : (
          <ReportLine key={title} name={title}>
            <Text>{content}</Text>
          </ReportLine>
        )
      )}
      {cancellationReport.map(({ title, content, indent }) =>
        indent ? (
          <IndentedReportLine key={title} name={title}>
            <Text>{content}</Text>
          </IndentedReportLine>
        ) : (
          <ReportLine key={title} name={title}>
            <Text>{content}</Text>
          </ReportLine>
        )
      )}
      {policiesReport.map(({ title, content, indent }) =>
        indent ? (
          <IndentedReportLine key={title} name={title}>
            <Text>{content}</Text>
          </IndentedReportLine>
        ) : (
          <ReportLine key={title} name={title}>
            <Text>{content}</Text>
          </ReportLine>
        )
      )}
    </ReportPart>
  )
}

function buildRequiredPaymentReport(
  paymentPolicy: PaymentPolicyForm,
  formatMessage: FormatMessage,
  venueCurrencyCode: string,
  defaultServiceCharge: number,
  defaultGratuity: number,
  taxRates?: TaxRate[]
) {
  const currencyFormatter = Intl.NumberFormat(TimeLocale.getLocale(), { style: 'currency', currency: venueCurrencyCode })
  const rows = []

  let requirePayment
  if (paymentPolicy.paymentRule === 'save_for_later') {
    requirePayment = formatMessage(PaymentPolicyLocales.paymentChoiceLater)
  } else if (paymentPolicy.paymentRule === 'advanced_payment') {
    requirePayment = formatMessage(PaymentPolicyLocales.paymentChoiceImmediately)
  } else {
    requirePayment = formatMessage(PaymentPolicyLocales.paymentChoiceNone)
  }
  rows.push({
    title: formatMessage(PaymentPolicyLocales.requirePayment),
    content: requirePayment,
    indent: false,
  })

  if (['save_for_later', 'advanced_payment'].includes(paymentPolicy.paymentRule)) {
    rows.push({
      title: formatMessage(PaymentPolicyLocales.partySize),
      content:
        paymentPolicy.partySizeType === 'gt'
          ? formatMessage(PaymentPolicyLocales.moreThanXGuests, { num: paymentPolicy.partySizeMin ?? 0 })
          : formatMessage(PaymentPolicyLocales.partySizeChoiceAll),
      indent: true,
    })
  }

  if (paymentPolicy.paymentRule === 'advanced_payment') {
    rows.push({
      title: formatMessage(PaymentPolicyLocales.creditCardCharge),
      content: formatMessage(PaymentPolicyLocales.chargeAndType, {
        formattedAmount: currencyFormatter.format(paymentPolicy.bookingCharge.amount ?? 0),
        type: paymentPolicy.bookingCharge.chargeType,
      }),
      indent: true,
    })
    if (paymentPolicy.charges.applyServiceCharge) {
      rows.push({
        title: formatMessage(PaymentPolicyLocales.serviceCharge),
        content:
          paymentPolicy.charges.serviceChargeType === 'DEFAULT_SERVICE_CHARGE'
            ? formatMessage(PaymentPolicyLocales.serviceChargePercent, {
                charge: defaultServiceCharge / 100,
              })
            : formatMessage(PaymentPolicyLocales.serviceChargePercent, {
                charge: (paymentPolicy.charges.serviceChargePercent ?? 0) / 100,
              }),
        indent: true,
      })
    }
    if (paymentPolicy.charges.applyTax) {
      const taxRate = taxRates?.find(tax => tax.id === paymentPolicy.charges.taxId)
      rows.push({
        title: formatMessage(PaymentPolicyLocales.tax),
        content: formatMessage(PaymentPolicyLocales.taxPercent, { name: taxRate?.name, rate: (taxRate?.rate ?? 0) / 100 }),
        indent: true,
      })
    }
    if (paymentPolicy.charges.applyGratuity) {
      rows.push({
        title: formatMessage(PaymentPolicyLocales.gratuity),
        content: getGratuityDisplay(paymentPolicy, formatMessage, defaultGratuity),
        indent: true,
      })
    }
  }

  return rows
}

function buildAdditionalSettingsReport(
  paymentPolicy: PaymentPolicyForm,
  allowChannelsWithoutCCHolds: boolean,
  formatMessage: FormatMessage
) {
  const rows = []

  if (paymentPolicy.paymentRule === 'save_for_later') {
    rows.push({
      title: formatMessage(PaymentPolicyLocales.additionalSettings),
      content: allowChannelsWithoutCCHolds
        ? formatMessage(PaymentPolicyLocales.bookOnUnsupportedLabel)
        : formatMessage(PaymentPolicyLocales.doNotBookOnUnsupported, {
            strong: (chunks: string[]) => <Text fontWeight="bold">{chunks}</Text>,
          }),
      indent: false,
    })
  }

  return rows
}

function buildCancellationReport(paymentPolicy: PaymentPolicyForm, formatMessage: FormatMessage, venueCurrencyCode: string) {
  const currencyFormatter = Intl.NumberFormat(TimeLocale.getLocale(), { style: 'currency', currency: venueCurrencyCode })
  const rows = []

  if (paymentPolicy.paymentRule === 'save_for_later') {
    rows.push({
      title: formatMessage(PaymentPolicyLocales.automaticCancellation),
      content:
        paymentPolicy.cancelCharge === 'NO_CHARGE'
          ? formatMessage(PaymentPolicyLocales.penaltyChoiceNone)
          : formatMessage(PaymentPolicyLocales.chargeAndType, {
              formattedAmount: currencyFormatter.format(paymentPolicy.autoCharge.amount ?? 0),
              type: paymentPolicy.autoCharge.chargeType,
            }),
      indent: false,
    })

    if (paymentPolicy.setChargeCutoff) {
      rows.push({
        title: formatMessage(PaymentPolicyLocales.cutoffTimeForFreeCancellations),
        content: formatMessage(PaymentPolicyLocales.upUntilGivenCutoff, {
          num: paymentPolicy.chargeCutoff.count,
          unit: paymentPolicy.chargeCutoff.unit,
          time: TimeOnly.fromSafe(paymentPolicy.chargeCutoff.beforeTime ?? '')?.formatSTime() ?? '0',
        }),
        indent: true,
      })
    }
  }

  rows.push({
    title: formatMessage(PaymentPolicyLocales.customerCanCancelModifyOnline),
    content: getCancelModifyDisplay(paymentPolicy, formatMessage),
    indent: false,
  })

  if (paymentPolicy.paymentRule !== 'save_for_later') {
    rows.push({
      title: formatMessage(PaymentPolicyLocales.automaticRefund),
      content: getRefundDisplay(paymentPolicy, formatMessage),
      indent: false,
    })

    if (paymentPolicy.setChargeCutoff) {
      rows.push({
        title: formatMessage(PaymentPolicyLocales.cutoffTimeForRefund),
        content: formatMessage(PaymentPolicyLocales.upUntilGivenCutoff, {
          num: paymentPolicy.chargeCutoff.count,
          unit: paymentPolicy.chargeCutoff.unit,
          time: TimeOnly.fromSafe(paymentPolicy.chargeCutoff.beforeTime ?? '')?.formatSTime() ?? '0',
        }),
        indent: true,
      })
    }
  }

  return rows
}

function buildPoliciesReport(paymentPolicy: PaymentPolicyForm, formatMessage: FormatMessage, policies: readonly Policy[]) {
  const policiesWithCustom = policies
    .map(({ id, name }) => ({ id, name }))
    .concat([
      {
        id: 'custom',
        name: formatMessage(PaymentPolicyLocales.customPolicy),
      },
    ])
  const [bookingPolicy, cancellationPolicy] = [paymentPolicy.bookingPolicy, paymentPolicy.cancelPolicy]
    .map(id => policiesWithCustom.find(policy => policy.id === id))
    .map(policy => policy?.name)

  return [
    {
      title: formatMessage(PaymentPolicyLocales.bookingPolicy),
      content: bookingPolicy ?? formatMessage(PaymentPolicyLocales.customPolicy),
      indent: false,
    },
    {
      title: formatMessage(PaymentPolicyLocales.cancellationPolicy),
      content: cancellationPolicy ?? formatMessage(PaymentPolicyLocales.customPolicy),
      indent: false,
    },
  ]
}

function getGratuityDisplay(paymentPolicy: PaymentPolicyForm, formatMessage: FormatMessage, defaultGratuity: number) {
  if (paymentPolicy.charges.gratuityType === 'CLIENT_GRATUITY') {
    return paymentPolicy.charges.requireGratuity
      ? formatMessage(PaymentPolicyLocales.requireClientGratuity)
      : formatMessage(PaymentPolicyLocales.gratuityChoiceClient)
  }
  if (paymentPolicy.charges.gratuityType === 'SPECIFIC_GRATUITY') {
    return formatMessage(PaymentPolicyLocales.gratuityPercent, { charge: (paymentPolicy.charges.gratuityPercent ?? 0) / 100 })
  }
  return formatMessage(PaymentPolicyLocales.gratuityPercent, { charge: defaultGratuity / 100 })
}

function getCancelModifyDisplay(paymentPolicy: PaymentPolicyForm, formatMessage: FormatMessage) {
  if (paymentPolicy.cancelCutoffChoice === 'ANY_TIME') {
    return formatMessage(PaymentPolicyLocales.cancelChoiceAnyTime)
  }
  if (paymentPolicy.cancelCutoffChoice === 'NEVER') {
    return formatMessage(PaymentPolicyLocales.cancelChoiceNever)
  }
  return formatMessage(PaymentPolicyLocales.upUntilGivenCutoff, {
    num: paymentPolicy.cancelCutoff.count ?? 0,
    unit: paymentPolicy.cancelCutoff.unit,
    time: TimeOnly.fromSafe(paymentPolicy.cancelCutoff.beforeTime ?? '')?.formatSTime() ?? '0',
  })
}

function getRefundDisplay(paymentPolicy: PaymentPolicyForm, formatMessage: FormatMessage) {
  if (paymentPolicy.refund === 'FULL_REFUND') {
    return formatMessage(PaymentPolicyLocales.refundChoiceFull)
  }
  if (paymentPolicy.refund === 'NO_REFUND') {
    return formatMessage(PaymentPolicyLocales.refundChoiceNone)
  }
  return formatMessage(PaymentPolicyLocales.partialRefundPercent, { charge: (paymentPolicy.autoCharge.amount ?? 0) / 100 })
}
