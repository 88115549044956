import { useMemo } from 'react'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { ReportLine, ReportPart } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { useAccessRuleContext } from '../../shared'
import { ShiftSettingsReportLink } from '../../shared/ShiftSettingsReportLink'
import { googleReserveSeatingOptionsDisplay, theForkSeatingOptionsDisplay } from '../integrationOptions'
import { SeatingAreasLocales } from '../SeatingAreas.locales'
import { SeatingAreasTestId } from '../SeatingAreas.testIds'
import { getShiftSeatingAreas } from '../SeatingAreas.zod'
import { DefaultSeatingAreas } from './DefaultSeatingAreas'
import type { AccessRuleReportProps } from '../../shared/AccessRuleReportProps'

export function SeatingAreasReport({ accessRule, position }: AccessRuleReportProps) {
  const { formatMessage } = useLocales()
  const { seatingAreaToTables, shifts } = useAccessRuleContext()

  const shiftSeatingAreas = useMemo(() => getShiftSeatingAreas(shifts, seatingAreaToTables), [shifts, seatingAreaToTables])
  const { isGoogleBookingEnabled, isTheforkIntegrationEnabled } = useAppContext().venueSettings
  const { selection, treatAsBlocked, googleReserveSeatingArea, theForkSeatingArea } = accessRule.seatingAreasAndTableCombinations

  return (
    <ReportPart caption={formatMessage(SeatingAreasLocales.title)} data-test={SeatingAreasTestId.report} position={position}>
      <ReportLine name={formatMessage(SeatingAreasLocales.multiSelectLabel)}>
        {selection.length === 0 ? (
          <ShiftSettingsReportLink title={formatMessage(SeatingAreasLocales.shiftSettingsSeatingAreas)}>
            <DefaultSeatingAreas shiftSeatingAreas={shiftSeatingAreas} />
          </ShiftSettingsReportLink>
        ) : (
          <Text>
            {selection
              .map(({ id, label, value: { isSeatingArea } }) =>
                isSeatingArea ? `${label} (${seatingAreaToTables.find(seatingArea => seatingArea.id === id)?.tables.length ?? 0})` : label
              )
              .join(', ')}
          </Text>
        )}
      </ReportLine>
      {treatAsBlocked && (
        <ReportLine name={formatMessage(SeatingAreasLocales.reportTreatTablesAsBlocked)}>
          <Text>{formatMessage(commonMessages.yes)}</Text>
        </ReportLine>
      )}
      {isTheforkIntegrationEnabled && (
        <ReportLine name={formatMessage(SeatingAreasLocales.theForkLabel)}>
          <Text>{theForkSeatingOptionsDisplay[theForkSeatingArea]}</Text>
        </ReportLine>
      )}
      {isGoogleBookingEnabled && (
        <ReportLine name={formatMessage(SeatingAreasLocales.googleReserveLabel)}>
          <Text>{googleReserveSeatingOptionsDisplay[googleReserveSeatingArea]}</Text>
        </ReportLine>
      )}
    </ReportPart>
  )
}
