import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { TimeOnly } from '@sevenrooms/core/timepiece'
import { ReportLine, ReportPart } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { blueGrey } from '@sevenrooms/react-components'
import { Box } from '@sevenrooms/react-components/components/Box'
import { Typography } from '@sevenrooms/react-components/components/Typography'
import { accessRulesMessages } from '../../../AccessRules.locales'
import { useAccessRuleContext } from '../../shared'
import { ShiftSettingsReportLink } from '../../shared/ShiftSettingsReportLink'
import { TimeRangeChip, TimeSlotChip } from '../../shared/TimeChips'
import { ScheduleLocales } from '../Schedule.locales'
import { ScheduleTestId } from '../Schedule.testIds'
import { formatDates, formatShifts, formatTimesList } from '../utils'
import { DefaultSchedule } from './DefaultSchedule'
import type { AccessRuleReportProps } from '../../shared/AccessRuleReportProps'

export function ScheduleReport({ accessRule, position }: AccessRuleReportProps) {
  const { formatMessage } = useLocales()
  const { allShifts } = useAccessRuleContext()

  const {
    selectedDays,
    dateRange: { startDate, endDate, isInfinite },
    accessTimeType,
    startTime,
    endTime,
    specificTimes,
    shiftCategories,
    restrictToShifts,
  } = accessRule.schedule

  const formattedDateRange = formatDates(formatMessage, startDate ?? new Date(0), isInfinite, endDate)
  const formattedShifts = formatShifts(shiftCategories, allShifts, restrictToShifts, formatMessage)
  const formattedTime = formatTimesList(
    accessTimeType,
    specificTimes,
    allShifts,
    shiftCategories,
    startTime ? TimeOnly.fromSafe(startTime) : undefined,
    endTime ? TimeOnly.fromSafe(endTime) : undefined
  )

  return (
    <ReportPart caption={formatMessage(accessRulesMessages.scheduleHeader)} data-test={ScheduleTestId.report} position={position}>
      {!accessRule.isOverride && (
        <ReportLine name={formatMessage(commonMessages.days)}>
          <DaysOfWeek selectedDays={selectedDays} />
        </ReportLine>
      )}
      <ReportLine name={formatMessage(accessRulesMessages.dateRange)}>
        <Text>{formattedDateRange}</Text>
      </ReportLine>
      <ReportLine name={formatMessage(commonMessages.times)}>
        <Box display="flex" gap="4px" maxWidth="400px" flexWrap="wrap" pt={1} pb={1}>
          {accessTimeType === 'SPECIFIC' ? formattedTime.map(time => <TimeSlotChip key={time} timeSlot={time} />) : null}
          {accessTimeType === 'TIME_RANGE' ? formattedTime.map(time => <TimeRangeChip key={time} timeInterval={time} />) : null}
          {accessTimeType === 'ALL' ? (
            <ShiftSettingsReportLink
              title={formatMessage(ScheduleLocales.shiftSettingsSchedule)}
              link={accessRulesMessages.allTimesDuringShiftCategoryLink}
            >
              <DefaultSchedule shiftCategories={shiftCategories} />
            </ShiftSettingsReportLink>
          ) : null}
        </Box>
      </ReportLine>
      <ReportLine name={formatMessage(accessRulesMessages.shiftCategories)}>
        <Text>{formattedShifts}</Text>
      </ReportLine>
    </ReportPart>
  )
}

function DaysOfWeek({ selectedDays }: { selectedDays: number[] }) {
  const allDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  const reorderedSelectedDays = selectedDays.map(day => (day + 1) % 7)

  return (
    <Box display="flex" gap="4px" pt={1} pb={1}>
      {allDays.map((day, index) => (
        <Box
          key={`${allDays[index]}`}
          sx={{
            width: 32,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '4px',
            p: 1,
            backgroundColor: reorderedSelectedDays.includes(index) ? blueGrey[100] : blueGrey[50],
          }}
        >
          <Typography
            variant="body2"
            color={reorderedSelectedDays.includes(index) ? 'text.primary' : 'common.white'}
            sx={{ fontSize: '14px' }}
          >
            {day}
          </Typography>
        </Box>
      ))}
    </Box>
  )
}
