import { useLocales } from '@sevenrooms/core/locales'
import { getHoursAndMinutes } from '@sevenrooms/core/timepiece'
import { ReportLine, ReportPart } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { CoreLocales } from '@sevenrooms/mgr-core/core.locales'
import { ShiftSettingsReportLink } from '../../shared/ShiftSettingsReportLink'
import { MAX_PARTY_SIZE } from '../Constants'
import { DurationsLocales } from '../Durations.locales'
import { DurationsDefault } from './DurationsDefault'
import type { AccessRuleReportProps } from '../../shared/AccessRuleReportProps'

export function DurationsReport({ accessRule, position }: AccessRuleReportProps) {
  const { formatMessage } = useLocales()

  const min = accessRule?.partySize?.min ?? 0
  const max = accessRule?.partySize?.max ?? Number.MAX_VALUE
  const guestMustSpecifyDuration = accessRule?.guestDurationPicker?.guestMustSpecifyDuration

  const durationByPartySizeEntries = Object.entries(accessRule.durations.durationsByPartySize)
  const showDefault =
    durationByPartySizeEntries.length === 0 || durationByPartySizeEntries.every(x => !x) || durationByPartySizeEntries.every(x => !x[1])

  return (
    <ReportPart caption={formatMessage(DurationsLocales.title)} data-test="access-rules-slideout-report-durations" position={position}>
      {showDefault ? (
        <ReportLine name={formatMessage(DurationsLocales.title)}>
          <ShiftSettingsReportLink title={formatMessage(DurationsLocales.shiftSettingsDurations)}>
            <DurationsDefault showGuestDurationPickerEnabledWarning={guestMustSpecifyDuration} minPartySize={min} maxPartySize={max} />
          </ShiftSettingsReportLink>
        </ReportLine>
      ) : (
        durationByPartySizeEntries
          .map(([partySize, duration]) => ({
            partySize: parseInt(partySize),
            duration,
          }))
          .sort((a, b) => a.partySize - b.partySize)
          .filter(x => {
            // special case for 10+ guests when min is greater than 10
            if (x.partySize === MAX_PARTY_SIZE && min > MAX_PARTY_SIZE) {
              return true
            }
            return x.duration && x.partySize >= min && x.partySize <= max
          })
          .map(({ partySize, duration }, index, { length }) => {
            const plusGreaterThan = index + 1 === length && partySize >= MAX_PARTY_SIZE

            return (
              <ReportLine
                key={`party-size-duration-${partySize}`}
                name={formatMessage(DurationsLocales.numGuests, { partySize, plusGreaterThan })}
              >
                {duration && <Text>{formatMessage(CoreLocales.durationTimeFormat, getHoursAndMinutes(duration))}</Text>}
              </ReportLine>
            )
          })
      )}
    </ReportPart>
  )
}
