import { useState, type PropsWithChildren } from 'react'
import { useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { accessRulesMessages } from '../../AccessRules.locales'
import { ShiftSettingsModal } from './ShiftSettingsModal'

interface ShiftSettingsReportLinkProps {
  title: string
  link?: { id: string; defaultMessage: string }
}

export function ShiftSettingsReportLink({ title, link, children }: PropsWithChildren<ShiftSettingsReportLinkProps>) {
  const { formatMessage } = useLocales()
  const [isShiftModalOpen, setIsShiftModalOpen] = useState(false)

  return (
    <>
      {formatMessage(link ?? accessRulesMessages.followShiftLink, {
        a: (chunks: string[]) => (
          <Button variant="tertiary" data-test="shift-settings-modal-link" onClick={() => setIsShiftModalOpen(true)} noPadding>
            {chunks}
          </Button>
        ),
      })}
      <ShiftSettingsModal active={isShiftModalOpen} title={title} onClose={() => setIsShiftModalOpen(false)}>
        {children}
      </ShiftSettingsModal>
    </>
  )
}
