import PeopleIcon from '@mui/icons-material/People'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { TimeOnly } from '@sevenrooms/core/timepiece'
import { ReportLine, ReportPart } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { Box } from '@sevenrooms/react-components/components/Box'
import { ShiftSettingsReportLink } from '../../shared/ShiftSettingsReportLink'
import { DefaultTimeChip, TimeSlotChip } from '../../shared/TimeChips'
import { customPacingKeysSort } from '../../ViewMode/utils'
import { PacingLocales } from '../Pacing.locales'
import { PacingTestId } from '../Pacing.testIds'
import { DefaultPacingSettings } from './DefaultPacingSettings'
import type { AccessRuleReportProps } from '../../shared/AccessRuleReportProps'

export function PacingReport({ accessRule, position }: AccessRuleReportProps) {
  const { formatMessage } = useLocales()
  const { venueStartOfDayHour, venueSettings } = useAppContext()

  const { maxCoversPerSeatingInterval, customPacing, isPacingHeld, excludeFromShiftPacing } = accessRule.pacing
  const customPacingKeysSorted = customPacingKeysSort(customPacing, venueStartOfDayHour)

  return (
    <ReportPart caption={formatMessage(PacingLocales.title)} data-test={PacingTestId.report} position={position}>
      {maxCoversPerSeatingInterval === null ? (
        <ReportLine name={formatMessage(PacingLocales.pacing)}>
          <ShiftSettingsReportLink title={formatMessage(PacingLocales.shiftSettingsPacing)}>
            <DefaultPacingSettings />
          </ShiftSettingsReportLink>
        </ReportLine>
      ) : (
        <>
          <ReportLine name={formatMessage(PacingLocales.pacing)}>
            <PacingDisplay
              maxCoversPerSeatingInterval={maxCoversPerSeatingInterval}
              customPacing={customPacing}
              customPacingKeysSorted={customPacingKeysSorted}
            />
          </ReportLine>
          {isPacingHeld && (
            <ReportLine name={formatMessage(PacingLocales.isPacingHeldOption)}>
              <Text>{formatMessage(commonMessages.yes)}</Text>
            </ReportLine>
          )}
        </>
      )}
      {venueSettings?.excludeFromShiftPacingEnabled && excludeFromShiftPacing && (
        <ReportLine name={formatMessage(PacingLocales.excludeFromShiftPacing)}>
          <Text>{formatMessage(commonMessages.yes)}</Text>
        </ReportLine>
      )}
    </ReportPart>
  )
}

const hasCustomEntries = (customPacing?: Record<string, number | null | undefined>): boolean =>
  !!customPacing && Object.keys(customPacing).length > 0

export function convertMinutesToTimeOnly(minutes: number): TimeOnly {
  const hours = Math.floor(minutes / 60)
  const mins = minutes % 60

  const formattedHours = String(hours).padStart(2, '0')
  const formattedMinutes = String(mins).padStart(2, '0')

  const time = `${formattedHours}:${formattedMinutes}`

  return TimeOnly.from(time)
}

function PacingDisplay({
  maxCoversPerSeatingInterval,
  customPacing,
  customPacingKeysSorted,
}: {
  maxCoversPerSeatingInterval: number
  customPacing: Record<string, number | null | undefined>
  customPacingKeysSorted: number[]
}) {
  const renderDefaultRow = () => {
    const showDefaultBox = hasCustomEntries(customPacing)

    return (
      <Box display="flex" alignItems="center" gap={2}>
        {showDefaultBox && <DefaultTimeChip />}

        <Box display="flex" alignItems="center" gap={1}>
          <PeopleIcon sx={{ fontSize: 16, color: 'text.primary' }} />
          <Text>{maxCoversPerSeatingInterval}</Text>
        </Box>
      </Box>
    )
  }

  const renderCustomRows = () => (
    <>
      {customPacingKeysSorted.map(
        minutes =>
          minutes !== undefined &&
          customPacing?.[minutes] !== null && (
            <Box key={minutes} display="flex" alignItems="center" gap={2}>
              <TimeSlotChip timeSlot={convertMinutesToTimeOnly(Number(minutes)).formatSTime()} />
              <Box display="flex" alignItems="center" gap={1}>
                <PeopleIcon sx={{ fontSize: 16, color: 'text.primary' }} />
                <Text>{customPacing[minutes] ?? maxCoversPerSeatingInterval}</Text>
              </Box>
            </Box>
          )
      )}
    </>
  )

  return (
    <Box display="flex" flexDirection="column" gap={1} maxWidth="100%">
      {renderDefaultRow()}
      {hasCustomEntries(customPacing) && renderCustomRows()}
    </Box>
  )
}
