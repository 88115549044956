import { useMemo, useCallback, type PropsWithChildren, useState } from 'react'
import type { AccessRuleAccessTime } from '@sevenrooms/core/domain'
import { useWatch } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { DateOnly } from '@sevenrooms/core/timepiece'
import type { SelectOption } from '@sevenrooms/core/ui-kit/core'
import { FormSelect, DateRangePicker, DayOfWeekPicker, Label, Button } from '@sevenrooms/core/ui-kit/form'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { Grid, Box, HStack, VStack, Window, Loader } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { useLazyGetAccessRuleOverridesQuery } from '@sevenrooms/lib-override-series-list'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { AccessRuleOverrides } from '../../AccessRuleOverrides'
import { useAccessRuleContext } from '../shared'
import { CustomTimeRangeSelector, ShiftSelector, SpecificTimeSlotsSelector } from './components'
import { ScheduleSeriesModal } from './components/ScheduleSeriesModal'
import { ScheduleLocales } from './Schedule.locales'
import { ScheduleTestId } from './Schedule.testIds'
import type { ScheduleProps, ScheduleWithSeriesProps } from './ScheduleProps'
import type { Moment } from 'moment'

export function Schedule(props: ScheduleWithSeriesProps) {
  return (
    <VStack spacing="sm">
      <Grid gridTemplateColumns="repeat(12, 1fr)" gap="lm" gridAutoRows="min-content">
        <Box maxWidth="100%" gridColumn="auto / span 8">
          {!props.showTimeOnly && <DaysPart {...props} />}
          <TimePart {...props} />
        </Box>
      </Grid>
    </VStack>
  )
}

function DaysPart({ field, selectedDate, series }: ScheduleWithSeriesProps) {
  const { venueTimezone, venueId } = useAppContext()
  const { formatMessage } = useLocales()
  const { accessRule, allShifts } = useAccessRuleContext()

  const [getBookingAccessOverrideList, bookingAccessOverrideList] = useLazyGetAccessRuleOverridesQuery()
  const [isOverridesModalOpen, setIsOverridesModalOpen] = useState(false)

  const today = useMemo(() => {
    const today = new Date(
      new Date().toLocaleString('en-US', {
        timeZone: venueTimezone,
      })
    )
    today.setHours(0, 0, 0, 0)
    return today
  }, [venueTimezone])
  const startDateCutoff = useMemo(() => {
    let comparisonDate = selectedDate ?? accessRule?.startDate?.toJsDate() ?? today
    if (comparisonDate < today) {
      comparisonDate = today
    }

    if (!comparisonDate) {
      return today
    }
    const seriesBeforeCurrent = series.filter(item => item.startDate.toJsDate() <= comparisonDate)
    const previousEndDate = seriesBeforeCurrent[seriesBeforeCurrent.length - 1]?.endDate
    if (!previousEndDate || previousEndDate.toJsDate() < today) {
      return today
    }
    const startDateCutoff = previousEndDate.toJsDate()
    startDateCutoff.setDate(startDateCutoff.getDate() + 1)
    return startDateCutoff
  }, [accessRule?.startDate, selectedDate, series, today])
  const endDateCutoff = useMemo(
    () => series.find(item => item.startDate.toJsDate() > startDateCutoff)?.startDate.toJsDate() ?? null,
    [series, startDateCutoff]
  )

  const isOutsideRange = useCallback(
    (date: Moment) => date.toDate() < startDateCutoff || (!!endDateCutoff && date.toDate() > endDateCutoff),
    [startDateCutoff, endDateCutoff]
  )

  const onViewModal = useCallback(async () => {
    if (accessRule && accessRule.startDate) {
      const startDate = DateOnly.fromJsDateSafe(accessRule.startDate.toJsDate() > today ? today : accessRule.startDate.toJsDate())
      if (startDate) {
        setIsOverridesModalOpen(true)
        await getBookingAccessOverrideList({
          entityId: accessRule.id,
          persistentId: accessRule.persistentId,
          venueId,
          startDate: startDate.toIso(),
        })
      }
    }
  }, [accessRule, getBookingAccessOverrideList, today, venueId])

  const renderCalendarInfo = () =>
    series.length > 0 ? (
      // eslint-disable-next-line react/forbid-component-props
      <Box className="DayPicker" pr="m" pl="m" pb="m">
        <Text>
          {formatMessage(ScheduleLocales.unselectableDatesLabel, {
            a: (chunks: string[]) => (
              <Button data-test={ScheduleTestId.unselectableDatesLabel} variant="tertiary" noPadding onClick={onViewModal}>
                {chunks}
              </Button>
            ),
          })}
        </Text>
      </Box>
    ) : null

  return (
    <VStack spacing="sm" mb="sm">
      <DetachedLabel text={formatMessage(ScheduleLocales.datesLabel)}>
        <DateRangePicker
          data-test={ScheduleTestId.dateRangePicker}
          id={ScheduleTestId.dateRangePicker}
          field={field.prop('dateRange')}
          infinite
          startDateProps={{
            isOutsideRange,
            renderCalendarInfo,
          }}
          endDateProps={{
            isOutsideRange,
            renderCalendarInfo,
          }}
        />
      </DetachedLabel>
      <DetachedLabel text={formatMessage(ScheduleLocales.daysOfWeekLabel)}>
        <DayOfWeekPicker
          data-test={ScheduleTestId.dateRangePicker}
          name={ScheduleTestId.dayOfWeekPicker}
          field={field.prop('selectedDays')}
        />
      </DetachedLabel>
      <Window active={isOverridesModalOpen}>
        <ScheduleSeriesModal
          onClose={() => {
            setIsOverridesModalOpen(false)
          }}
        >
          {bookingAccessOverrideList.isSuccess ? (
            <AccessRuleOverrides overrides={bookingAccessOverrideList.data} allShifts={allShifts} />
          ) : (
            <Loader />
          )}
        </ScheduleSeriesModal>
      </Window>
    </VStack>
  )
}

function TimePart({ field, showTimeOnly }: ScheduleProps) {
  const { shifts } = useAccessRuleContext()
  const { formatMessage } = useLocales()

  const accessTimeChoices: SelectOption<AccessRuleAccessTime>[] = useMemo(
    () => [
      {
        id: 'ALL',
        label: formatMessage(ScheduleLocales.timeSlotAll),
      },
      {
        id: 'TIME_RANGE',
        label: formatMessage(ScheduleLocales.timeSlotCustom),
      },
      {
        id: 'SPECIFIC',
        label: formatMessage(ScheduleLocales.timeSlotSpecific),
      },
    ],
    [formatMessage]
  )

  const accessTimeType = useWatch(field.prop('accessTimeType'))

  const accessTimeSelect = (
    <FormSelect
      data-test={ScheduleTestId.timeSlotFilterType}
      searchable={false}
      options={accessTimeChoices}
      field={field.prop('accessTimeType')}
    />
  )

  return (
    <VStack spacing="sm">
      {showTimeOnly ? (
        accessTimeSelect
      ) : (
        <Label primary={formatMessage(ScheduleLocales.timeSlotsLabel)} secondary={formatMessage(ScheduleLocales.timeSlotsDescription)}>
          {accessTimeSelect}
        </Label>
      )}
      {accessTimeType === 'ALL' && <ShiftSelector field={field} />}
      {accessTimeType === 'TIME_RANGE' && <CustomTimeRangeSelector field={field} />}
      {accessTimeType === 'SPECIFIC' && <SpecificTimeSlotsSelector field={field} />}
      {shifts.length === 0 && (
        <HStack data-test={ScheduleTestId.noOverlapsWarning} spacing="s">
          <Icon name="VMSWeb-warning" color="warning" size="lg" />
          <Text>{formatMessage(ScheduleLocales.noOverlapsWarning)}</Text>
        </HStack>
      )}
    </VStack>
  )
}

type DetachedLabelProps = PropsWithChildren<{
  text: string
}>

function DetachedLabel({ text, children }: DetachedLabelProps) {
  return (
    <Box>
      <Box mb="s">
        <Text>{text}</Text>
      </Box>
      {children}
    </Box>
  )
}
