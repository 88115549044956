import { type ReactNode, useCallback, useMemo } from 'react'
import type { AccessRule, SeatingAreaToTables, TableCombos } from '@sevenrooms/core/domain'
import { type Field, useWatch } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { Checkbox, FieldErrors, Label, MultiSelectTree, type FlattenTreeNode, type TreeNode } from '@sevenrooms/core/ui-kit/form'
import { HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Anchor, Text } from '@sevenrooms/core/ui-kit/typography'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { AccessRuleTooltip, useAccessRuleContext } from '../../shared'
import { SeatingAreasLocales } from '../SeatingAreas.locales'
import { SeatingAreasTestId } from '../SeatingAreas.testIds'
import { SeatingAreaIntegrationFields } from './SeatingAreaIntegrationFields'
import type { ShiftSeatingAreas } from './ShiftSeatingAreas'
import type { SeatingAreasForm } from '../SeatingAreas.zod'

export interface SeatingAreasFieldsProps {
  field: Field<SeatingAreasForm>
  shiftSeatingAreas: ShiftSeatingAreas[]
  conflict: ReactNode
  isPrivateEventAccessRule?: boolean
  disableEdit?: boolean
  showRestrictionCheckbox?: boolean
}

export function SeatingAreasFields({
  field,
  shiftSeatingAreas,
  conflict,
  disableEdit,
  isPrivateEventAccessRule = false,
  showRestrictionCheckbox = true,
}: SeatingAreasFieldsProps) {
  const { seatingAreaToTables, accessRule, tableCombinations } = useAccessRuleContext()
  const { isGoogleBookingEnabled, isTheforkIntegrationEnabled } = useAppContext().venueSettings
  const { formatMessage } = useLocales()

  const selection = useWatch(field.prop('selection'))
  const treatAsBlocked = field.prop('treatAsBlocked')

  const treeData = useMemo(
    () => getMultiSelectOptions(seatingAreaToTables, shiftSeatingAreas, tableCombinations, isPrivateEventAccessRule, accessRule),
    [seatingAreaToTables, shiftSeatingAreas, tableCombinations, isPrivateEventAccessRule, accessRule]
  )
  const optionWarning = useCallback(
    (option: FlattenTreeNode<TreeValue>) => {
      const node = option.value
      const { isBookable } = node.value
      const checked = node.checked || (option.meta.partial && !node.expanded)
      return checked && !isBookable ? <>{conflict}</> : null
    },
    [conflict]
  )

  return (
    <>
      <VStack spacing="m">
        <VStack spacing="sm">
          <VStack spacing="s">
            <Label
              noMargin
              primary={
                <HStack spacing="xs">
                  <Text>{formatMessage(SeatingAreasLocales.multiSelectLabel)}</Text>
                  <HelpTooltip />
                </HStack>
              }
            />
            <MultiSelectTree
              disabled={disableEdit}
              data-test={SeatingAreasTestId.seatingAreasMultiSelect}
              id={SeatingAreasTestId.seatingAreasMultiSelect}
              placeholder={formatMessage(SeatingAreasLocales.multiSelectPlaceholder)}
              treeData={treeData}
              field={field.prop('selection')}
              extraNodeContent={optionWarning}
              onChange={(_, selection) => {
                if (selection.length === 0) {
                  treatAsBlocked.set(false)
                }
              }}
            />
          </VStack>
          {showRestrictionCheckbox && (
            <HStack spacing="xs">
              <Checkbox
                data-test={SeatingAreasTestId.treatAsBlockedCheckbox}
                field={treatAsBlocked}
                disabled={selection?.length === 0 || disableEdit}
              >
                {formatMessage(SeatingAreasLocales.checkboxLabel)}
              </Checkbox>
              <AccessRuleTooltip data-test={SeatingAreasTestId.treatAsBlockedTooltip}>
                <Text color="lightFont">
                  {formatMessage(SeatingAreasLocales.treatAsBlockedTooltip, {
                    a: (chunks: string[]) => (
                      <Anchor href="/help?return_to=/hc/en-us/articles/7873014719003-Access-Rule-Seating-Areas-and-Tables">{chunks}</Anchor>
                    ),
                  })}
                </Text>
              </AccessRuleTooltip>
            </HStack>
          )}
        </VStack>
        {(isGoogleBookingEnabled || isTheforkIntegrationEnabled) && (
          <SeatingAreaIntegrationFields
            theForkField={field.prop('theForkSeatingArea')}
            googleReserveField={field.prop('googleReserveSeatingArea')}
          />
        )}
      </VStack>

      <FieldErrors data-test={SeatingAreasTestId.seatingAreasErrors} field={field} />
    </>
  )
}

function HelpTooltip() {
  const { formatMessage } = useLocales()

  const messageProps = {
    strong: (chunks: string[]) => (
      <Text color="lightFont" fontWeight="bold">
        {chunks}
      </Text>
    ),
  }

  return (
    <AccessRuleTooltip data-test={SeatingAreasTestId.helpTooltip}>
      <Text color="lightFont" fontWeight="bold">
        {formatMessage(SeatingAreasLocales.helpTooltipImportant)}
      </Text>
      <Text color="lightFont">{formatMessage(SeatingAreasLocales.helpTooltipSingleSeatingArea, messageProps)}</Text>
      <Text color="lightFont">{formatMessage(SeatingAreasLocales.helpTooltipMultipleSeatingArea, messageProps)}</Text>
    </AccessRuleTooltip>
  )
}

export type TreeValue = SeatingAreasForm['selection'][0]['value']
export function getMultiSelectOptions(
  seatingAreaToTables: SeatingAreaToTables[],
  shiftToSeatingAreas: ShiftSeatingAreas[],
  tableCombinations: TableCombos[],
  isPrivateEventsEnabled: boolean,
  accessRule?: AccessRule
): TreeNode<TreeValue>[] {
  const tree: TreeNode<TreeValue>[] = []

  const shiftSeatingAreas = extractShiftSeatingAreas(shiftToSeatingAreas)

  const seatingAreaHeader = createSeatingAreaHeader(isPrivateEventsEnabled)

  if (seatingAreaToTables.length > 0) {
    processSeatingAreasAndTables(seatingAreaToTables, shiftSeatingAreas, accessRule, isPrivateEventsEnabled, seatingAreaHeader, tree)
  }

  if (isPrivateEventsEnabled && seatingAreaHeader) {
    tree.push(seatingAreaHeader)
    addTableCombinations(tableCombinations, tree)
  }

  return tree
}

function extractShiftSeatingAreas(shiftToSeatingAreas: ShiftSeatingAreas[]) {
  return shiftToSeatingAreas.map(({ seatingAreas, tables }) => ({
    seatingAreas: seatingAreas.map(({ id }) => id),
    tables: tables.map(({ id }) => id),
  }))
}

function createSeatingAreaHeader(isPrivateEventsEnabled: boolean): TreeNode<TreeValue> | null {
  return isPrivateEventsEnabled
    ? {
        id: 'seating_areas',
        label: 'Seating Areas',
        section: true,
        readOnly: true,
        value: { isTable: false, isTableCombination: false, isSeatingArea: false, isBookable: false },
        checked: false,
        children: [],
      }
    : null
}

function processSeatingAreasAndTables(
  seatingAreaToTables: SeatingAreaToTables[],
  shiftSeatingAreas: { seatingAreas: string[]; tables: string[] }[],
  accessRule: AccessRule | undefined,
  isPrivateEventsEnabled: boolean,
  seatingAreaHeader: TreeNode<TreeValue> | null,
  tree: TreeNode<TreeValue>[]
) {
  for (const seatingArea of seatingAreaToTables) {
    const seatingAreaNode = createSeatingAreaNode(seatingArea, shiftSeatingAreas, accessRule)
    const treeTarget = isPrivateEventsEnabled ? seatingAreaHeader?.children : tree
    treeTarget?.push(seatingAreaNode)
  }
}

function createSeatingAreaNode(
  seatingArea: SeatingAreaToTables,
  shiftSeatingAreas: { seatingAreas: string[]; tables: string[] }[],
  accessRule: AccessRule | undefined
): TreeNode<TreeValue> {
  const checked = accessRule?.seatingAreaIDS.includes(seatingArea.id ?? '')
  const children = createTableNodes(seatingArea.tables, shiftSeatingAreas, accessRule, checked ?? false)

  return {
    id: seatingArea.id,
    label: seatingArea.name ?? 'No name',
    value: {
      isTable: false,
      isTableCombination: false,
      isSeatingArea: true,
      isBookable: shiftSeatingAreas.every(({ seatingAreas }) => seatingAreas.length === 0 || seatingAreas.includes(seatingArea.id)),
    },
    checked,
    expanded: !checked && children.some(({ checked }) => checked),
    children,
  }
}

function createTableNodes(
  tables: { id: string; itemCode: string }[],
  shiftSeatingAreas: { seatingAreas: string[]; tables: string[] }[],
  accessRule: AccessRule | undefined,
  seatingAreaChecked: boolean
): TreeNode<TreeValue>[] {
  return tables.map(table => ({
    id: table.id,
    label: table.itemCode,
    value: {
      isTable: true,
      isTableCombination: false,
      isSeatingArea: false,
      isBookable: shiftSeatingAreas.every(({ tables }) => tables.length === 0 || tables.includes(table.id)),
    },
    checked: accessRule?.tableIDS.includes(table.id) || seatingAreaChecked,
  }))
}

function createTableComboLabel(tableCombo: TableCombos) {
  const cleanLabel = tableCombo.label.replace(/[()]/g, '')
  return `${cleanLabel} (${tableCombo.partySizeMin}-${tableCombo.partySizeMax} guests)`
}

function addTableCombinations(tableCombinations: TableCombos[], tree: TreeNode<TreeValue>[]) {
  if (tableCombinations.length > 0) {
    const tableCombosChildren = tableCombinations.map(tableCombo => ({
      id: tableCombo.id,
      label: createTableComboLabel(tableCombo),
      value: { isTable: false, isTableCombination: true, isSeatingArea: false, isBookable: true },
    }))

    tree.push({
      id: 'table_combos',
      label: 'Table Combinations',
      section: true,
      readOnly: true,
      value: { isTable: false, isTableCombination: true, isSeatingArea: false, isBookable: true },
      checked: false,
      children: tableCombosChildren,
    })
  }
}
