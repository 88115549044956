import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Modal, ModalActions, ModalBody, ModalFooter, ModalHeader, ModalTitle, Window } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { accessRulesMessages } from '../../AccessRules.locales'
import type { PropsWithChildren } from 'react'

export interface ShiftSettingsModalProps {
  active: boolean
  title: string
  onClose: () => void
}

export function ShiftSettingsModal({ active, title, children, onClose }: PropsWithChildren<ShiftSettingsModalProps>) {
  const { formatMessage } = useLocales()

  return (
    <Window active={active}>
      <Modal ariaLabel="main" width="700px" maxWidth="700px">
        <ModalHeader
          onClose={e => {
            e.preventDefault()
            onClose()
          }}
        >
          <ModalTitle
            title={title}
            subTitle={formatMessage(accessRulesMessages.shiftSettingsSubtitle, {
              b: (chunks: string[]) => <Text fontWeight="bold">{chunks}</Text>,
            })}
          />
        </ModalHeader>

        <ModalBody>{children}</ModalBody>

        <ModalFooter>
          <ModalActions>
            <Button
              variant="primary"
              onClick={e => {
                e.preventDefault()
                onClose()
              }}
              data-test="ok-button"
            >
              {formatMessage(commonMessages.done)}
            </Button>
          </ModalActions>
        </ModalFooter>
      </Modal>
    </Window>
  )
}
