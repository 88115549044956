import DoDisturbAltIcon from '@mui/icons-material/DoDisturbAlt'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { HTMLContent } from '@sevenrooms/core/ui-kit/core'
import { PreviewImage, ReportLine, ReportPart } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { Box } from '@sevenrooms/react-components/components/Box'
import { useAccessRuleContext } from '../../shared'
import { GuestFacingLocales } from '../GuestFacing.locales'
import { GuestFacingTestId } from '../GuestFacing.testIds'
import type { AccessRuleReportProps } from '../../shared/AccessRuleReportProps'

export function GuestFacingReport({ accessRule, position }: AccessRuleReportProps) {
  const { formatMessage } = useLocales()
  const { experiences } = useAccessRuleContext()

  const { timeslotDescription, title, description, image, offer, minSpend, allowUnsupported } = accessRule.guestFacing
  const noneMessage = formatMessage(commonMessages.none)

  const linkedOffer = experiences.find(({ id }) => id === offer)

  return (
    <ReportPart caption={formatMessage(GuestFacingLocales.title)} data-test={GuestFacingTestId.report} position={position}>
      <ReportLine name={formatMessage(GuestFacingLocales.widgetDescriptionLabel)}>
        <Text>{timeslotDescription || noneMessage}</Text>
      </ReportLine>
      <ReportLine name={formatMessage(GuestFacingLocales.titleLabel)}>
        <Text>{title || noneMessage}</Text>
      </ReportLine>
      <ReportLine name={formatMessage(GuestFacingLocales.descriptionLabel)}>
        {description ? <HTMLContent content={description} data-test={GuestFacingTestId.htmlDescription} /> : <Text>{noneMessage}</Text>}
      </ReportLine>
      <ReportLine name={formatMessage(GuestFacingLocales.photoLabel)}>
        {image ? <PreviewImage maxHeight="100px" alt={image.name} src={image.preview ?? image.rawUrl} /> : <Text>{noneMessage}</Text>}
      </ReportLine>
      {offer && linkedOffer ? (
        <ReportLine name={formatMessage(GuestFacingLocales.offer)}>
          <Box display="flex" alignItems="flex-start" gap={1}>
            <Box display="flex" alignItems="center">
              {linkedOffer?.status === 'ACTIVE' ? (
                <TaskAltIcon sx={{ fontSize: 16, color: 'success.main' }} />
              ) : (
                <DoDisturbAltIcon sx={{ fontSize: 16, color: 'error.main' }} />
              )}
            </Box>
            <Text>{linkedOffer?.name}</Text>
          </Box>
        </ReportLine>
      ) : null}
      {minSpend && (
        <ReportLine name={formatMessage(GuestFacingLocales.minSpendLabel)}>
          <Text>{minSpend}</Text>
        </ReportLine>
      )}
      {allowUnsupported && (
        <ReportLine name={formatMessage(GuestFacingLocales.bookOnUnsupportedLabel)}>
          <Text>{formatMessage(commonMessages.yes)}</Text>
        </ReportLine>
      )}
    </ReportPart>
  )
}
