import { DateOnly } from '@sevenrooms/core/timepiece'
import type { DateRangePickerForm } from '@sevenrooms/core/ui-kit/form'

export function getClonedDateRange(dateRange: DateRangePickerForm): DateRangePickerForm {
  const { startDate, endDate, isInfinite } = dateRange
  const copyDateRange = { ...dateRange }
  if (startDate && startDate < new Date()) {
    copyDateRange.startDate = new Date()
  }
  if (!isInfinite && endDate && endDate < new Date()) {
    copyDateRange.endDate = new Date()
    copyDateRange.isInfinite = false
  }
  return copyDateRange
}

export function areObjectsEqual(object1: { [key: string]: unknown } | {}, object2: { [key: string]: unknown } | {}) {
  if (Object.keys(object1).length !== Object.keys(object2).length) {
    return false
  }

  for (const key in object1) {
    if (!Object.prototype.hasOwnProperty.call(object1, key)) {
      continue
    }

    const val1 = (object1 as { [key: string]: unknown })[key]
    const val2 = (object2 as { [key: string]: unknown })[key]
    if (!areValuesEqual(val1, val2)) {
      return false
    }
  }
  return true
}

export function areArraysEqual(array1: unknown[], array2: unknown[]) {
  if (array1.length !== array2.length) {
    return false
  }

  let result = true
  array1.forEach((val1: unknown, index: number) => {
    if (!result) {
      return
    }

    const val2 = array2[index]
    if (!areValuesEqual(val1, val2)) {
      result = false
    }
  })

  return result
}

function areValuesEqual(val1: unknown, val2: unknown) {
  if (Array.isArray(val1)) {
    if (!Array.isArray(val2) || !areArraysEqual(val1, val2)) {
      return false
    }
  } else if (val1 instanceof Date && val2 instanceof Date) {
    // Only check for date, not time
    const dateTime1 = DateOnly.fromDate(val1)
    const dateTime2 = DateOnly.fromDate(val2)
    if (!dateTime1.isEqualTo(dateTime2)) {
      return false
    }
  } else if (
    typeof val1 === 'object' &&
    typeof val2 === 'object' &&
    val1 !== undefined &&
    val1 !== null &&
    val2 !== undefined &&
    val2 !== null
  ) {
    if (!areObjectsEqual(val1, val2)) {
      return false
    }
  } else if (val1 !== val2) {
    return false
  }

  return true
}
