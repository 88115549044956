import MenuBookIcon from '@mui/icons-material/MenuBook'
import PeopleIcon from '@mui/icons-material/People'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { ReportLine, ReportPart } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { Box } from '@sevenrooms/react-components/components/Box'
import { ReservationCoverLimitLocales } from '../ReservationCoverLimit.locales'
import { ReservationCoverLimitTestId } from '../ReservationCoverLimit.testIds'
import type { AccessRuleReportProps } from '../../shared/AccessRuleReportProps'

export function ReservationCoverLimitReport({ accessRule, position }: AccessRuleReportProps) {
  const { formatMessage } = useLocales()

  const { count, type, guaranteeBookings } = accessRule.reservationCoverLimit

  return (
    <ReportPart
      caption={formatMessage(ReservationCoverLimitLocales.title)}
      data-test={ReservationCoverLimitTestId.report}
      position={position}
    >
      <ReportLine name={formatMessage(ReservationCoverLimitLocales.maxLimitPerDay)}>
        <Text>
          <CoverLimit count={count} type={type} />
        </Text>
      </ReportLine>
      <ReportLine name={formatMessage(ReservationCoverLimitLocales.guaranteeBookingsReportKey)}>
        <Text>{formatMessage(guaranteeBookings ? commonMessages.yes : commonMessages.no)}</Text>
      </ReportLine>
    </ReportPart>
  )
}

function CoverLimit({ count, type }: { count: number | null; type: string }) {
  const { formatMessage } = useLocales()

  const renderIconAndValue = (icon: React.ReactNode, value: number) => (
    <Box display="flex" alignItems="center">
      <Box display="inline-flex" sx={{ mt: type === 'RESERVATIONS' ? '-2px' : 0 }}>
        {icon}
      </Box>
      <Box pl={1}>
        <Text>{value}</Text>
      </Box>
    </Box>
  )

  if (type === 'UNLIMITED' || count === 0) {
    return <Text>{formatMessage(ReservationCoverLimitLocales.defaultsValue)}</Text>
  }

  return type === 'COVERS'
    ? renderIconAndValue(<PeopleIcon sx={{ fontSize: 16, color: 'text.primary' }} />, count ?? 0)
    : renderIconAndValue(<MenuBookIcon sx={{ fontSize: 16, color: 'text.primary' }} />, count ?? 0)
}
