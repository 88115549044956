import { useLocales } from '@sevenrooms/core/locales'
import { generatePath } from '@sevenrooms/core/navigation'
import { Banner } from '@sevenrooms/core/ui-kit/layout'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { routes } from '@sevenrooms/routes'
import { accessRulesMessages } from '../../AccessRules.locales'

export function DurationWidgetRequired() {
  const { formatMessage } = useLocales()
  const { venueUrlKey, venueSettings } = useAppContext()
  const widgetSettingsPath =
    venueSettings?.newResWidgetEnabled && venueSettings?.forceRedirectsOnLegacyWidget
      ? generatePath(
          routes.manager2.settings.widgetSettings.reservationWidgetSettingsV2.path,
          {
            venueKey: venueUrlKey,
          },
          { activeTab: 'searchAvailability', activeEl: 'durationPicker' }
        )
      : generatePath(routes.manager.settings.widgets.reservationWidgetSettings.path, {
          venueKey: venueUrlKey,
        })
  const widgetSettingsUrl = `https://${window.location.host}${widgetSettingsPath}`

  return (
    <Banner
      title={formatMessage(accessRulesMessages.durationWidgetRequiredTitle)}
      description={formatMessage(accessRulesMessages.durationWidgetRequiredBody)}
      type="warning"
      action={formatMessage(accessRulesMessages.configureDurationPicker)}
      onAction={() => window.open(widgetSettingsUrl, '_blank')}
    />
  )
}
