import type { Field } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { TimeOnly } from '@sevenrooms/core/timepiece'
import { FormSelect, ImageUploader, Checkbox, FormInput, Label, TextArea, FormNumberInput } from '@sevenrooms/core/ui-kit/form'
import { Grid, Box, HStack, PreviewImage, VStack } from '@sevenrooms/core/ui-kit/layout'
import { FormEditor, defaultConfig } from '@sevenrooms/core/ui-kit/optional'
import { Anchor, Text } from '@sevenrooms/core/ui-kit/typography'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import WidgetImg from '../../../images/widget_timeslot_detail_example3x.png'
import MinSpendImg from '../../../images/widget_timeslot_detail_min_spend_example.png'
import { AccessRuleTooltip, Divider, useAccessRuleContext } from '../../shared'
import { GuestFacingLocales } from '../GuestFacing.locales'
import { GuestFacingTestId } from '../GuestFacing.testIds'
import { NotBookableTooltip } from './NotBookableTooltip'
import { TimeSlot } from './TimeSlot'
import { useUpgradesSummaryFromField } from './useUpgradesSummary'
import type { GuestFacingProps } from './GuestFacingProps'
import type { PaymentPolicyForm } from '../../PaymentPolicy/PaymentPolicy.zod'
import type { GuestFacingForm } from '../GuestFacing.zod'
import type { PropsWithChildren } from 'react'

const toolbarButtons = {
  moreRich: {
    buttons: ['insertLink'],
  },
  moreMisc: {
    buttons: ['bold', 'italic', 'underline'],
  },
}
const config = {
  ...defaultConfig,
  toolbarInline: false,
  toolbarBottom: true,
  toolbarButtons,
  toolbarButtonsSM: toolbarButtons,
  width: '100%',
}

export function GuestFacingFields({ field, bookingChannels, paymentPolicy, isPrivateEventAccessRule }: GuestFacingProps) {
  const { experiences, widgetSettings } = useAccessRuleContext()
  const { formatMessage } = useLocales()
  const { venueSettings } = useAppContext()

  const options = experiences
    .sort((e1, e2) => {
      if (e1.status > e2.status) {
        return 1
      }
      if (e1.status < e2.status) {
        return -1
      }
      return 0
    })
    .map(({ id, name, status }) => ({ id, label: `${name} (${status})` }))

  return (
    <VStack spacing="lm">
      <VStack spacing="m">
        <Text fontWeight="bold">{formatMessage(GuestFacingLocales.widgetDescriptionHeader)}</Text>
        <Grid gridTemplateColumns="repeat(12, 1fr)" gap="lm" gridAutoRows="min-content">
          <Box maxWidth="100%" gridColumn="auto / span 7">
            <Label primary={formatMessage(GuestFacingLocales.widgetDescriptionLabel)}>
              <FormInput
                data-test={GuestFacingTestId.timeSlotDescription}
                placeholder={formatMessage(GuestFacingLocales.widgetDescriptionPlaceholder)}
                field={field.prop('timeslotDescription')}
              />
            </Label>
          </Box>
          <Box maxWidth="100%" gridColumn="auto / span 5">
            <VStack spacing="xs">
              <Text color="secondaryFont" fontStyle="italic" fontSize="s">
                {formatMessage(GuestFacingLocales.timeSlotPreviewLabel)}
              </Text>
              <Box width="152px">
                <TimeSlot timeSlot={TimeOnly.from('17:00')} widgetSettings={widgetSettings} field={field} currencyCode="USD" />
              </Box>
            </VStack>
          </Box>
        </Grid>
      </VStack>

      <Divider />

      <Grid gridTemplateColumns="repeat(12, 1fr)" gap="lm" gridAutoRows="min-content">
        <Box maxWidth="100%" gridColumn="auto / span 7">
          <VStack spacing="m">
            <Text>
              {formatMessage(GuestFacingLocales.widgetDetailHeader, {
                strong: (chunks: string[]) => <Text fontWeight="bold">{chunks}</Text>,
              })}
            </Text>
            <Label primary={formatMessage(GuestFacingLocales.titleLabel)}>
              <FormInput
                data-test={GuestFacingTestId.timeSlotTitle}
                placeholder={formatMessage(GuestFacingLocales.titlePlaceholder)}
                field={field.prop('title')}
              />
            </Label>
            <VStack>
              <Label primary={formatMessage(GuestFacingLocales.descriptionLabel)} />
              <FormEditor
                data-test={GuestFacingTestId.longDescription}
                field={field.prop('description')}
                config={config}
                isLoyaltyAndPerksEnabled={venueSettings?.isLoyaltyAndPerksEnabled}
                referralProgramEnabled={venueSettings?.referralProgramEnabled}
              />
            </VStack>
            <BundledUpgradesList bundledUpgrades={paymentPolicy.prop('bundledUpgrades')} />
            <ImageUploader
              data-test={GuestFacingTestId.imageUpload}
              label={formatMessage(GuestFacingLocales.uploadLabel)}
              field={field.prop('image')}
              variant="slideout"
              disableEdit
            />
          </VStack>
        </Box>
        <Box maxWidth="100%" gridColumn="auto / span 5">
          <VStack spacing="xs">
            <Text color="secondaryFont" fontStyle="italic" fontSize="s">
              {formatMessage(GuestFacingLocales.widgetPreviewLabel)}
            </Text>
            <PreviewImage alt={formatMessage(GuestFacingLocales.widgetPreviewLabel)} src={WidgetImg} />
          </VStack>
        </Box>
      </Grid>

      <Divider />

      <Grid gridTemplateColumns="repeat(12, 1fr)" gap="lm" gridAutoRows="min-content">
        <Box maxWidth="100%" gridColumn="auto / span 7">
          <VStack>
            <Label primary={<OfferLabel isPrivateEventAccessRule={isPrivateEventAccessRule} />} />
            <FormSelect
              data-test={GuestFacingTestId.offerDropdown}
              options={options}
              field={field.prop('offer')}
              withEmpty
              placeholder={formatMessage(GuestFacingLocales.offerPlaceholder)}
            />
          </VStack>
        </Box>
      </Grid>

      <Divider />

      <Grid gridTemplateColumns="repeat(12, 1fr)" gap="lm" gridAutoRows="min-content">
        <Box maxWidth="100%" gridColumn="auto / span 12">
          <Checkbox
            data-test={GuestFacingTestId.allowOnUnsupportedChannels}
            field={field.prop('allowUnsupported')}
            description={formatMessage(GuestFacingLocales.bookOnUnsupportedDescription)}
          >
            <Text>
              {formatMessage(GuestFacingLocales.bookOnUnsupportedLabel)}
              <NotBookableTooltip field={field} bookingChannels={bookingChannels} />
            </Text>
          </Checkbox>
        </Box>
      </Grid>

      {isPrivateEventAccessRule ? (
        <>
          <Divider />
          <MinSpend field={field.prop('minSpend')} />
        </>
      ) : null}
    </VStack>
  )
}

function OfferLabel({ isPrivateEventAccessRule }: { isPrivateEventAccessRule?: boolean }) {
  const { formatMessage } = useLocales()
  return (
    <LabelWithTooltip
      data-test={GuestFacingTestId.offerLabel}
      label={
        isPrivateEventAccessRule
          ? formatMessage(GuestFacingLocales.offerOrGroupBookingSpaceLabel)
          : formatMessage(GuestFacingLocales.offerLabel)
      }
    >
      <Text color="lightFont">{formatMessage(GuestFacingLocales.offerTooltip1)}</Text>
      <Text color="lightFont">{formatMessage(GuestFacingLocales.offerTooltip2)}</Text>
      <Anchor href="https://help.sevenrooms.com/hc/en-us/articles/360027285472-Offers-Experiences-Perks-and-Events-">
        {formatMessage(GuestFacingLocales.offerLink)}
      </Anchor>
    </LabelWithTooltip>
  )
}

function LabelWithTooltip({ children, label, 'data-test': testId }: PropsWithChildren<{ label: string; 'data-test': string }>) {
  return (
    <HStack data-test={testId} spacing="xs">
      <Text>{label}</Text>
      <AccessRuleTooltip title={label}>{children}</AccessRuleTooltip>
    </HStack>
  )
}

function BundledUpgradesList(props: { bundledUpgrades: Field<PaymentPolicyForm['bundledUpgrades']> }) {
  const { formatMessage } = useLocales()
  const upgradesSummary = useUpgradesSummaryFromField(props.bundledUpgrades)

  const includesLabel = (
    <LabelWithTooltip data-test={GuestFacingTestId.includesLabel} label={formatMessage(GuestFacingLocales.includesLabel)}>
      <Text color="lightFont">{formatMessage(GuestFacingLocales.includesTooltip)}</Text>
    </LabelWithTooltip>
  )

  return upgradesSummary ? (
    <Label primary={includesLabel}>
      <TextArea data-test={GuestFacingTestId.paidUpgrades} resize="none" value={upgradesSummary} disabled />
    </Label>
  ) : null
}

function MinSpend(props: { field: Field<GuestFacingForm['minSpend']> }) {
  const { formatMessage } = useLocales()
  return (
    <Grid gridTemplateColumns="repeat(12, 1fr)" gap="lm" gridAutoRows="min-content">
      <Box maxWidth="100%" gridColumn="auto / span 7">
        <Label primary={formatMessage(GuestFacingLocales.minSpendLabel)} secondary={formatMessage(GuestFacingLocales.minSpendDescription)}>
          <FormNumberInput data-test={GuestFacingTestId.minSpend} field={props.field} />
        </Label>
      </Box>
      <Box maxWidth="100%" gridColumn="auto / span 5">
        <VStack spacing="xs">
          <Text color="secondaryFont" fontStyle="italic" fontSize="s">
            {formatMessage(GuestFacingLocales.minSpendPreviewLabel)}
          </Text>
          <PreviewImage alt={formatMessage(GuestFacingLocales.widgetPreviewLabel)} src={MinSpendImg} />
        </VStack>
      </Box>
    </Grid>
  )
}
