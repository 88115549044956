import { ReportLine, Box } from '@sevenrooms/core/ui-kit/layout'
import type { PropsWithChildren } from 'react'

interface IndentedReportLineProps {
  name: string
}

export function IndentedReportLine({ name, children }: PropsWithChildren<IndentedReportLineProps>) {
  return <ReportLine name={<Box pl="lm">{name}</Box>}>{children}</ReportLine>
}
