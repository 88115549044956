import { Fragment } from 'react'
import { useLocales } from '@sevenrooms/core/locales'
import { ReportLine, ReportPart } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { FormattedTimeBefore } from '../../shared/FormattedTimeBefore'
import { IndentedReportLine } from '../../shared/IndentedReportLine'
import { BookingChannelsLocales } from '../BookingChannels.locales'
import { BookingChannelsTestId } from '../BookingChannels.testIds'
import { BookingChannelAudience } from './BookingChannelAudience'
import type { AccessRuleReportProps } from '../../shared/AccessRuleReportProps'

export function BookingsChannelsReport({ accessRule, position }: AccessRuleReportProps) {
  const { formatMessage } = useLocales()
  const { venueSettings } = useAppContext()
  const { bookingChannels } = accessRule
  return (
    <ReportPart caption={formatMessage(BookingChannelsLocales.title)} data-test={BookingChannelsTestId.report} position={position}>
      {bookingChannels.map((tier, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={idx}>
          {venueSettings.isExclusiveArAccessEnabled ? (
            <>
              <ReportLine name={formatMessage(BookingChannelsLocales.tierAndBookingChannelsLabel, { count: idx + 1 })}>
                <Text>{tier.selected.map(({ label }) => label).join(', ')}</Text>
              </ReportLine>
              <IndentedReportLine name={formatMessage(BookingChannelsLocales.audiences, { count: idx + 1 })}>
                <BookingChannelAudience tier={tier} />
              </IndentedReportLine>
            </>
          ) : (
            <ReportLine name={formatMessage(BookingChannelsLocales.tierAndAudiencesLabel, { count: idx + 1 })}>
              <Text>{tier.selected.map(({ label }) => label).join(', ')}</Text>
            </ReportLine>
          )}
          {bookingChannels.length > 1 && (
            <IndentedReportLine name={formatMessage(BookingChannelsLocales.bookingStartTime, { count: idx + 1 })}>
              <FormattedTimeBefore {...tier.startTime} />
            </IndentedReportLine>
          )}
        </Fragment>
      ))}
    </ReportPart>
  )
}
