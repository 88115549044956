import { useMemo } from 'react'
import type { Shift } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import {
  HStack,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  Report,
  ReportLine,
  ReportPart,
  ReportSection,
} from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { useAccessRuleContext } from '../../shared'
import { PacingLocales } from '../Pacing.locales'
import { collateTimeSlots, generateTimeSlots, HIGH_PACING_THRESHOLD } from '../utils'
import type { PacingForm } from '../Pacing.zod'

export interface ConflictProps {
  onClose: () => void
  customPacing: PacingForm['customPacing']
  maxCoversPerSeatingInterval: number | null
}

export function ConflictModal({ onClose, customPacing, maxCoversPerSeatingInterval }: ConflictProps) {
  const { formatMessage } = useLocales()
  const { shifts } = useAccessRuleContext()
  const { startOfDayTime } = useAppContext().venueSettings
  const timeSlots = useMemo(() => generateTimeSlots(startOfDayTime), [startOfDayTime])
  const arDefaultPacing = maxCoversPerSeatingInterval || 0

  return (
    <Modal ariaLabel="main" width="700px" maxWidth="700px">
      <ModalHeader
        onClose={e => {
          e.preventDefault()
          onClose()
        }}
      >
        <ModalTitle
          title={formatMessage(PacingLocales.conflictModalHeading)}
          subTitle={formatMessage(PacingLocales.conflictModalSubtext)}
        />
      </ModalHeader>

      <ModalBody>
        <ReportSection title={formatMessage(PacingLocales.shiftPacingSettings)}>
          <Report>
            {shifts.map((shift: Shift) => (
              <ReportPart
                key={shift.id}
                caption={[
                  `${shift.name}`,
                  formatMessage(PacingLocales.shiftPacing),
                  formatMessage(PacingLocales.arPacing),
                  formatMessage(PacingLocales.pacingRemaining),
                ]}
              >
                <PacingLine
                  name={formatMessage(PacingLocales.defaultPacingLabel)}
                  shiftPacing={shift.defaultPacing}
                  arPacing={arDefaultPacing}
                />
                {Object.entries(collateTimeSlots(shift, arDefaultPacing, customPacing))
                  .sort((a, b) => a[0].localeCompare(b[0]))
                  .map(([timeSlot, { shiftPacing, arPacing }]) => (
                    <PacingLine
                      key={timeSlot}
                      name={timeSlots.find(({ id }) => id === timeSlot)?.label}
                      shiftPacing={shiftPacing}
                      arPacing={arPacing}
                    />
                  ))}
              </ReportPart>
            ))}
          </Report>
        </ReportSection>
      </ModalBody>
      <ModalFooter />
    </Modal>
  )
}

function PacingLine({ name, shiftPacing, arPacing }: { name?: string; shiftPacing: number; arPacing: number }) {
  const diff = Math.max(shiftPacing - arPacing, 0)

  return (
    <ReportLine name={name} firstColWidth="200px">
      <Text>{shiftPacing}</Text>
      <Text>{arPacing}</Text>
      <HStack spacing="xs">
        <Text fontWeight={diff <= HIGH_PACING_THRESHOLD ? 'bold' : 'normal'}>{diff}</Text>
        {diff <= HIGH_PACING_THRESHOLD && <Icon name="VMSWeb-warning" size="sm" color="warning" />}
      </HStack>
    </ReportLine>
  )
}
