import { defineMessages } from '@sevenrooms/core/locales'

export const DurationsLocales = defineMessages({
  title: {
    id: 'ar.durations.title',
    defaultMessage: 'Durations',
  },
  subCaption: {
    id: 'ar.durations.subCaption',
    defaultMessage: 'Override Shift Durations to extend or shorten the allotted time for a reservation based on the type of experience.',
  },
  configureDurationsSubHeader: {
    id: 'ar.durations.subCaption',
    defaultMessage: 'Override Shift Durations',
  },
  description: {
    id: 'ar.durations.description',
    defaultMessage: 'Ex: Customize tasting menu Durations to give these gusts more time than à la carte diners.',
  },
  headerShift: {
    id: 'ar.durations.headerPartySizeTimeRanges',
    defaultMessage: 'Shift',
  },
  headerShiftTimeRanges: {
    id: 'ar.durations.headerPartySizeTimeRanges',
    defaultMessage: 'Shift <span>/ Time Range</span>',
  },
  headerPartyDuration: {
    id: 'ar.durations.headerPartyDuration',
    defaultMessage: 'Duration',
  },
  headerPartySize: {
    id: 'ar.durations.headerPartySize',
    defaultMessage: 'Party Size',
  },
  followShiftSettingsLabel: {
    id: 'ar.durations.followShiftSettingsLabel',
    defaultMessage: 'Follow <a>Shift Settings</a> for Durations',
  },
  defaultSettingsModalTitle: {
    id: 'ar.durations.defaultSettingsModalTitle',
    defaultMessage: 'Shift Settings - Durations',
  },
  numGuests: {
    id: 'ar.durations.numGuests',
    defaultMessage: `{partySize}{plusGreaterThan, select, true {+} other {}} {partySize, plural,
      one {guest}
      other {guests}}`,
  },
  partySizeRange: {
    id: 'ar.durations.partySizeRange',
    defaultMessage: '{start} - {end}{plusGreaterThan, select, true {+} other {}}',
  },
  partySizeRangeSingle: {
    id: 'ar.durations.partySizeRangeSingle',
    defaultMessage: '{partySize}{plusGreaterThan, select, true {+} other {}}',
  },
  bufferTimeAfterReservation: {
    id: 'ar.durations.bufferTimeAfterReservation',
    defaultMessage: 'Buffer Time After Reservation: {buffer} minutes for party sizes greater than {minBufferPartySize}',
  },
  useDurationsAllPartySizes: {
    id: 'ar.durations.useDurationsAllPartySizes',
    defaultMessage: 'Assign same Duration to all Party Sizes',
  },
  useDurationsByPartySize: {
    id: 'ar.durations.useDurationsByPartySize',
    defaultMessage: 'Assign customized Duration per Party Size',
  },
  errorDurationRequired: {
    id: 'ar.durations.errorDurationRequired',
    defaultMessage: 'Duration required',
  },
  buffersNote: {
    id: 'ar.durations.buffersNote',
    defaultMessage: 'If Buffers are set on the Shift, they will be applied on top of Durations set here.',
  },
  partySizeNote: {
    id: 'ar.durations.partySizeNote',
    defaultMessage: 'Party Sizes in gray cannot be edited because they are outside the Party Size range for the Shift or Access Rule.',
  },
  guestDurationPickerEnabledWarning: {
    id: 'ar.durations.guestDurationPickerEnabledWarning',
    defaultMessage:
      'Access Rules with the Guest Duration Picker cannot also use custom Durations. Remove the Guest Duration Picker from this Access Rule in order to use custom Durations.',
  },
  missingDurationError: {
    id: 'ar.durations.missingDurationError',
    defaultMessage:
      'Durations are require for all Party Sizes included in this Access Rule. Add a Duration in order to save this Access Rule.',
  },
  shiftSettingsDurations: {
    id: 'ar.edit.shiftSettingsDurations',
    defaultMessage: 'Shift Settings - Durations',
  },
} as const)
