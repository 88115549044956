import PeopleIcon from '@mui/icons-material/People'
import { useLocales } from '@sevenrooms/core/locales'
import { ReportLine, ReportPart } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { Box } from '@sevenrooms/react-components/components/Box'
import { ShiftSettingsReportLink } from '../../shared/ShiftSettingsReportLink'
import { PartySizeLocales } from '../PartySize.locales'
import { PartySizeTestId } from '../PartySize.testIds'
import { DefaultPartySize } from './DefaultPartySize'
import type { AccessRuleReportProps } from '../../shared/AccessRuleReportProps'

export function PartySizeReport({ accessRule, position }: AccessRuleReportProps) {
  const { formatMessage } = useLocales()

  const { min, max } = accessRule.partySize

  return (
    <ReportPart caption={formatMessage(PartySizeLocales.title)} data-test={PartySizeTestId.report} position={position}>
      <ReportLine name={formatMessage(PartySizeLocales.title)}>
        <Text>
          {min && max ? (
            <Box display="flex" alignItems="center" gap="4px">
              <PeopleIcon sx={{ fontSize: 16, color: 'text.primary' }} />
              <Text>{`${min} - ${max}`}</Text>
            </Box>
          ) : (
            <ShiftSettingsReportLink title={formatMessage(PartySizeLocales.shiftSettingsPartySize)}>
              <DefaultPartySize />
            </ShiftSettingsReportLink>
          )}
        </Text>
      </ReportLine>
    </ReportPart>
  )
}
