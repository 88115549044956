import { useMemo } from 'react'
import { type Field, useWatch } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { FormSelect, type RadioChoice } from '@sevenrooms/core/ui-kit/form'
import { Box } from '@sevenrooms/core/ui-kit/layout'
import { useDurationOptions } from '@sevenrooms/mgr-core/hooks/useDurationOptions'
import { DisplayIfSelected } from '../../ChargeSettings'
import { RadioSection } from '../../PaymentPolicy/components/radio/RadioComponents'
import { DurationsLocales } from '../Durations.locales'
import { DurationsByPartySizeFields } from './DurationsByPartySizeFields'
import type { PartySizeForm } from '../../PartySize/PartySize.zod'
import type { DurationsForm } from '../Durations.zod'
import type { DurationsFieldProps } from '../DurationsFieldProps'

interface DurationsRadioButtonsProps extends DurationsFieldProps {
  partySizeField: Field<PartySizeForm>
}

export function DurationsRadioButtons({ field, partySizeField }: DurationsRadioButtonsProps) {
  const { formatMessage } = useLocales()
  const durationOptions = useDurationOptions()

  const configType = useWatch(field.prop('durationsConfigType'))

  const choices: RadioChoice<DurationsForm['durationsConfigType']>[] = useMemo(
    () => [
      {
        value: 'ALL_PARTY_SIZES',
        label: formatMessage(DurationsLocales.useDurationsAllPartySizes),
        description: (
          <DisplayIfSelected field={field.prop('durationsConfigType')} option="ALL_PARTY_SIZES">
            <Box m="s">
              <FormSelect
                withEmpty
                field={field.prop('durationAllPartySizes')}
                options={durationOptions}
                searchable={false}
                data-test="access-rule-duration-all-party-sizes"
                disabled={configType !== 'ALL_PARTY_SIZES'}
              />
            </Box>
          </DisplayIfSelected>
        ),
      },
      {
        value: 'BY_PARTY_SIZE',
        label: formatMessage(DurationsLocales.useDurationsByPartySize),
        description: (
          <DisplayIfSelected field={field.prop('durationsConfigType')} option="BY_PARTY_SIZE">
            <Box m="s" pr="m" width="100%">
              <DurationsByPartySizeFields field={field} partySizeField={partySizeField} />
            </Box>
          </DisplayIfSelected>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formatMessage, field, configType]
  )
  return (
    <RadioSection
      choices={choices}
      field={field.prop('durationsConfigType')}
      primaryLabel={formatMessage(DurationsLocales.configureDurationsSubHeader)}
      data-test="access-rule-duration-radio-buttons"
    />
  )
}
