import type { AccessRule, Shift } from '@sevenrooms/core/domain'
import type { DateOnly } from '@sevenrooms/core/timepiece'

export interface AccessRuleProps {
  rule: AccessRule
  date: DateOnly
  startSortOrder: number
  endSortOrder: number
  specificTimeSortOrders?: number[]
  shifts: Shift[]
}

export type SortableAccessRule = Pick<
  AccessRule,
  | 'name'
  | 'id'
  | 'restrictToShifts'
  | 'accessTimeType'
  | 'shiftCategories'
  | 'specificTimeSortOrders'
  | 'startTimeSortOrder'
  | 'endTimeSortOrder'
  | 'internalDisplayColor'
>

interface AccessRuleData<T extends SortableAccessRule> extends Omit<AccessRuleProps, 'rule' | 'date'> {
  rule: T
}

export function accessRuleSort<T extends SortableAccessRule>(a: AccessRuleData<T>, b: AccessRuleData<T>) {
  if (a.startSortOrder !== b.startSortOrder) {
    return a.startSortOrder - b.startSortOrder
  }

  if (a.specificTimeSortOrders && !b.specificTimeSortOrders) {
    return 1
  }

  if (b.specificTimeSortOrders && !a.specificTimeSortOrders) {
    return -1
  }

  const nameA = a.rule.name.toUpperCase()
  const nameB = b.rule.name.toUpperCase()
  if (nameA < nameB) {
    return -1
  }
  if (nameA > nameB) {
    return 1
  }

  return 0
}

export function getAccessRulesDisplayOrder<T extends SortableAccessRule>(accessRules: T[], shifts: Shift[]): AccessRuleData<T>[] {
  const uniqueSpecificTimes = new Set<string>()

  return accessRules
    .flatMap(rule => {
      const ruleData = { rule }

      const matchingShifts = shifts
        .filter(
          shift =>
            (!rule.restrictToShifts && rule.accessTimeType !== 'ALL') ||
            (shift.shiftCategory && rule.shiftCategories.includes(shift.shiftCategory))
        )
        .filter(shift => shift.startTimeSortOrder !== undefined && shift.endTimeSortOrder !== undefined)

      if (rule.accessTimeType === 'ALL') {
        return matchingShifts.map(shift => ({
          ...ruleData,
          startSortOrder: shift.startTimeSortOrder ?? 0,
          endSortOrder: shift.endTimeSortOrder ?? 0,
          shifts: [shift],
        }))
      }

      if (rule.accessTimeType === 'SPECIFIC' && rule.specificTimeSortOrders && !uniqueSpecificTimes.has(rule.id)) {
        uniqueSpecificTimes.add(rule.id)
        return {
          ...ruleData,
          startSortOrder: Math.min(...rule.specificTimeSortOrders),
          endSortOrder: Math.max(...rule.specificTimeSortOrders) + 1,
          specificTime: rule.startTimeSortOrder,
          specificTimeSortOrders: rule.specificTimeSortOrders,
          shifts: matchingShifts,
        }
      }

      if (rule.accessTimeType === 'TIME_RANGE' && rule.startTimeSortOrder !== undefined && rule.endTimeSortOrder !== undefined) {
        return { ...ruleData, startSortOrder: rule.startTimeSortOrder, endSortOrder: rule.endTimeSortOrder, shifts: matchingShifts }
      }

      return []
    })
    .sort(accessRuleSort)
}
