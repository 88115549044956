import type { AccessRuleStartEndTimesByShiftDisplay, Pacing, ShiftCategory } from '@sevenrooms/core/domain'
import { notNullish } from '@sevenrooms/core/ui-kit/form'

const SHIFT_CATEGORY_SORT = {
  BREAKFAST: 0,
  BRUNCH: 1,
  LUNCH: 2,
  DAY: 3,
  DINNER: 4,
  LEGACY: 5,
}

export function sortShiftCategories(shiftCategories: ShiftCategory[]) {
  const sortedShiftCategories = shiftCategories.slice().sort((a, b) => {
    if (SHIFT_CATEGORY_SORT[a] < SHIFT_CATEGORY_SORT[b]) {
      return -1
    }

    if (SHIFT_CATEGORY_SORT[a] > SHIFT_CATEGORY_SORT[b]) {
      return 1
    }
    return 0
  })

  return sortedShiftCategories
}

export function buildValueByShiftTimesDisplayList(
  startEndTimesByShiftDisplay: Array<AccessRuleStartEndTimesByShiftDisplay>,
  shiftCategories: ShiftCategory[]
) {
  const sortedShiftCategories = sortShiftCategories(shiftCategories)
  const startEndTimesByShiftAssociative = startEndTimesByShiftDisplay.reduce(
    (a, v) => ({ ...a, [v.shiftCategory]: v.times }),
    {}
  ) as Record<ShiftCategory, string[]>

  return sortedShiftCategories
    .map(shiftCategory => {
      if (shiftCategory in startEndTimesByShiftAssociative) {
        const [startTime, endTime] = startEndTimesByShiftAssociative[shiftCategory]
        return `${startTime} - ${endTime}`
      }
      return undefined
    })
    .filter(notNullish)
}

export function buildValueByShiftTimesDisplay(
  startEndTimesByShiftDisplay: Array<AccessRuleStartEndTimesByShiftDisplay>,
  shiftCategories: ShiftCategory[]
) {
  return buildValueByShiftTimesDisplayList(startEndTimesByShiftDisplay, shiftCategories).join(', ')
}

export function customPacingKeysSort(customPacing: Pacing | Record<string, number | null | undefined>, startOfDayHour: number) {
  return Object.keys(customPacing)
    .map(minsString => {
      const mins = parseInt(minsString)
      const sortOrder = minutesToSortOrder(mins, startOfDayHour)
      return [sortOrder, mins] as const
    })
    .sort((a, b) => a[0] - b[0])
    .map(x => x[1])
}

const minsInInterval = 15
const numOfIntervalsPerHour = 4
const rollOverFactor = 95

function minutesToSortOrder(mins: number, startOfDayHour: number): number {
  const midnightSortOrder = mins / minsInInterval
  const sortOrder = midnightSortOrder - numOfIntervalsPerHour * startOfDayHour

  return sortOrder > 0 ? sortOrder : rollOverFactor + sortOrder
}
