import { defineMessages } from '@sevenrooms/core/locales'

export const BookingChannelsLocales = defineMessages({
  title: {
    id: 'ar.bookingChannels.title',
    defaultMessage: 'Booking Channels and Audiences',
  },
  description: {
    id: 'ar.bookingChannels.description',
    defaultMessage: 'Ex: Only allow these reservations to be booked on my Reservation Widget and Google Reserve.',
  },
  subCaption: {
    id: 'ar.bookingChannels.subCaption',
    defaultMessage: 'Customize who can book this inventory and through which channels it can be booked.',
  },
  addTier: {
    id: 'ar.bookingChannels.addTier',
    defaultMessage: 'Add another Audience Tier',
  },
  audienceTier: {
    id: 'ar.bookingChannels.audienceTier',
    defaultMessage: 'Audience Tier {num}',
  },
  buttonTooltipBody1: {
    id: 'ar.bookingChannels.buttonTooltipBody1',
    defaultMessage:
      'This setting allows you to make reservations available to different Audiences at different times. For example, you could allow guests using the SevenRooms Reservation Widget to book three months ahead of the reservation date, but restrict third party booking channels to one month ahead of the reservation date.',
  },
  buttonTooltipBody2: {
    id: 'ar.bookingChannels.buttonTooltipBody2',
    defaultMessage: 'Note that this will override your Guest Booking Start Time setting in the Booking Window section.',
  },
  buttonTooltipLinkText: {
    id: 'ar.bookingChannels.buttonTooltipLinkText',
    defaultMessage: 'More about this setting',
  },
  startTimeLabel: {
    id: 'ar.bookingChannels.startTimeLabel',
    defaultMessage: 'Guest Booking Start Time',
  },
  startTimeSubLabel: {
    id: 'ar.bookingChannels.startTimeSubLabel',
    defaultMessage: 'Allow guests to book starting from',
  },
  searchAudiences: {
    id: 'ar.bookingChannels.searchAudiences',
    defaultMessage: 'Search Audiences',
  },
  startTimeRequired: {
    id: 'ar.bookingChannels.startTimeRequired',
    defaultMessage: 'Guest Booking Start Time is required',
  },
  audienceRequired: {
    id: 'ar.bookingChannels.audienceRequired',
    defaultMessage: 'At least one audience is required',
  },
  audienceLabel: {
    id: 'ar.bookingChannels.audienceLabel',
    defaultMessage: 'Audience:',
  },
  bookingChannelsLabel: {
    id: 'ar.bookingChannels.bookingChannelLabel',
    defaultMessage: 'Booking Channels:',
  },
  audienceTierLabel: {
    id: 'ar.bookingChannels.audienceTierLabel',
    defaultMessage: 'Audience Tier {count}:',
  },
  canBook: {
    id: 'ar.bookingChannels.canBook',
    defaultMessage: `{before, select,
      0 {
        {unit, select,
          DAYS {
            {count, plural,
              one {can book # day before reservation time}
              other {can book # days before reservation time}
            }
          }
          WEEKS {
            {count, plural,
              one {can book # week before reservation time}
              other {can book # weeks before reservation time}
            }
          }
          MONTHS {
            {count, plural,
              one {can book # month before reservation time}
              other {can book # months before reservation time}
            }
          }
          other {
            {count, plural,
              one {can book # hour before reservation time}
              other {can book # hours before reservation time}
            }
          }
        }
      }
      other {
        {unit, select,
          DAYS {
            {count, plural,
              one {can book # day before {before}}
              other {can book # days before {before}}
            }
          }
          WEEKS {
            {count, plural,
              one {can book # week before {before}}
              other {can book # weeks before {before}}
            }
          }
          MONTHS {
            {count, plural,
              one {can book # month before {before}}
              other {can book # months before {before}}
            }
          }
          other {
            {count, plural,
              one {can book # hour before {before}}
              other {can book # hours before {before}}
            }
          }
        }
      }
    }`,
  },
  startTimeCutoffError: {
    id: 'ar.bookingChannels.startTimeCutoffError',
    defaultMessage: 'Guest Booking Start Time cannot start after Guest Booking Cutoff Time (configured in the Booking Window section)',
  },
  tierAndBookingChannelsLabel: {
    id: 'ar.bookingChannels.tierAndBookingChannelsLabel',
    defaultMessage: 'Tier {count} Booking Channels',
  },
  tierAndAudiencesLabel: {
    id: 'ar.bookingChannels.tierAndAudienceLabel',
    defaultMessage: 'Tier {count} Audiences',
  },
  tierAndAudiencesNoneLabel: {
    id: 'ar.bookingChannels.tierAndAudiencesNoneLabel',
    defaultMessage: 'These reservations are bookable by all guests.',
  },
  tierAndAudiencesBookLabel: {
    id: 'ar.bookingChannels.tierAndAudiencesBookLabel',
    defaultMessage: 'These reservations are only visible to guest with:',
  },
  tierAndAudiencesViewLabel: {
    id: 'ar.bookingChannels.tierAndAudiencesViewLabel',
    defaultMessage: 'These reservations are publicly visible, but only bookable by guests with specific Client Tags:',
  },
  tierAndAudiencesClientTagsDeletedWarningLabel: {
    id: 'ar.bookingChannels.tierAndAudiencesClientTagsDeletedWarningLabel',
    defaultMessage: 'Client Tag has been deleted.',
  },
  guestTagsRequired: {
    id: 'ar.bookingChannels.guestTagsRequired',
    defaultMessage: 'At least one client tag is expected',
  },
  inventoryAvailabilityAvailableLabel: {
    id: 'ar.bookingChannels.inventoryAvailabilityAvailableLabel',
    defaultMessage: 'These reservations are bookable by all guests.',
  },
  inventoryAvailabilityVisibleOnlyByGuestsWithCertainClientTagsLabel: {
    id: 'ar.bookingChannels.inventoryAvailabilityVisibleOnlyByGuestsWithCertainClientTagsLabel',
    defaultMessage: 'These reservations are only visible to guests with specific Client Tags.',
  },
  inventoryAvailabilityVisibleButBookableByGuestsWithCertainClientTagsLabel: {
    id: 'ar.bookingChannels.inventoryAvailabilityVisibleButBookableByGuestsWithCertainClientTagsLabel',
    defaultMessage: 'These reservations are publicly visible, but only bookable by guests with specific Client Tags.',
  },
  inventoryShowTo: {
    id: 'ar.bookingChannels.inventoryShowTo',
    defaultMessage: 'Reservations visible to:',
  },
  searchTagPlaceholder: {
    id: 'ar.bookingChannels.searchTagPlaceholder',
    defaultMessage: 'Search and select tag(s)',
  },
  searchClientTagPlaceholder: {
    id: 'ar.bookingChannels.searchClientTagPlaceholder',
    defaultMessage: 'Search and select client tag(s)',
  },
  clientTagCanBook: {
    id: 'ar.bookingChannels.clientTagCanBook',
    defaultMessage: 'Client tags that can book:',
  },
  allGuests: {
    id: 'ar.bookingChannels.allGuests',
    defaultMessage: 'All guests',
  },
  showThisOn: {
    id: 'ar.bookingChannels.showThisOn',
    defaultMessage: 'Show this on:',
  },
  audiences: {
    id: 'ar.bookingChannels.audiences',
    defaultMessage: 'Audiences',
  },
  bookingStartTime: {
    id: 'ar.bookingChannels.bookingStartTime',
    defaultMessage: 'Booking Start Time',
  },
} as const)
