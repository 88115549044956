import { useWatch, type Field } from '@sevenrooms/core/form'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { HStack, Pair, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { accessRulesMessages } from '../../../AccessRules.locales'
import { Join, useAccessRuleContext } from '../../shared'
import { googleReserveSeatingOptionsDisplay, theForkSeatingOptionsDisplay } from '../integrationOptions'
import { SeatingAreasLocales } from '../SeatingAreas.locales'
import type { SeatingAreasForm } from '../SeatingAreas.zod'
import type { ReactNode } from 'react'

export interface CollapsedSeatingAreasProps {
  field: Field<SeatingAreasForm>
  conflict: ReactNode
}

export function CollapsedSeatingAreas({ field, conflict }: CollapsedSeatingAreasProps) {
  const { formatMessage } = useLocales()
  const { seatingAreaToTables } = useAccessRuleContext()
  const { isGoogleBookingEnabled, isTheforkIntegrationEnabled } = useAppContext().venueSettings

  const selection = useWatch(field.prop('selection'))
  const treatAsBlocked = useWatch(field.prop('treatAsBlocked'))
  const theForkSeatingArea = useWatch(field.prop('theForkSeatingArea'))
  const googleReserveSeatingArea = useWatch(field.prop('googleReserveSeatingArea'))

  return (
    <Text fontSize="m">
      <VStack spacing="s">
        <Pair
          left={formatMessage(SeatingAreasLocales.seatingAreasLabel)}
          right={
            selection.length === 0 ? (
              formatMessage(accessRulesMessages.followShift)
            ) : (
              <Join delimiter=", " flexWrap="wrap" justifyContent="left" overflow="auto">
                {selection.map(({ id, label, value: { isBookable, isSeatingArea } }) => (
                  <HStack key={id} spacing="xs">
                    <Text>{label}</Text>
                    {isSeatingArea && <Text>({seatingAreaToTables.find(seatingArea => seatingArea.id === id)?.tables.length ?? 0})</Text>}
                    {!isBookable && conflict}
                  </HStack>
                ))}
              </Join>
            )
          }
        />
        {treatAsBlocked && (
          <Pair left={formatMessage(SeatingAreasLocales.treatTablesAsBlocked)} right={formatMessage(commonMessages.yes)} />
        )}
        {isTheforkIntegrationEnabled && (
          <Pair left={formatMessage(SeatingAreasLocales.theForkCollapsedLabel)} right={theForkSeatingOptionsDisplay[theForkSeatingArea]} />
        )}
        {isGoogleBookingEnabled && (
          <Pair
            left={formatMessage(SeatingAreasLocales.googleReserveCollapsedLabel)}
            right={googleReserveSeatingOptionsDisplay[googleReserveSeatingArea]}
          />
        )}
      </VStack>
    </Text>
  )
}
