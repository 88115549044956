import { skipToken } from '@reduxjs/toolkit/dist/query'
import { useCallback } from 'react'
import { useGetPerkQuery } from '@sevenrooms/core/api'
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { Banner, Box, Report } from '@sevenrooms/core/ui-kit/layout'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { Box as ReactComponentsBox } from '@sevenrooms/react-components/components/Box'
import { ThemeProvider, vmsTheme } from '@sevenrooms/react-components/components/ThemeProvider'
import { routes } from '@sevenrooms/routes'
import { accessRulesMessages } from '../../AccessRules.locales'
import { BookingsChannelsReport } from '../BookingChannels/components'
import { BookingWindowReport } from '../BookingWindow/components'
import { DurationsReport } from '../Durations/components/DurationsReport'
import { DurationWidgetRequired } from '../DurationWidgetRequired'
import { GuestDurationPickerReport } from '../GuestDurationPicker/components'
import { GuestFacingReport } from '../GuestFacing/components'
import { PacingReport } from '../Pacing/components'
import { PartySizeReport } from '../PartySize/components'
import { PaymentPolicyReport } from '../PaymentPolicy/components'
import { ReservationCoverLimitReport } from '../ReservationCoverLimit/components'
import { ReservationTagsReport } from '../ReservationTags/components'
import { ScheduleReport } from '../Schedule/components'
import { SeatingAreasReport } from '../SeatingAreas/components'
import { useAccessRuleContext } from '../shared'
import { UpgradesReport } from '../Upgrades/components'
import type { AccessRuleForm } from '../../AccessRule.zod'

export interface ViewModeProps {
  accessRule: AccessRuleForm
}

export function ViewMode({ accessRule }: ViewModeProps) {
  const nav = useNavigation()
  const { formatMessage } = useLocales()
  const { venueId, venueKey } = useVenueContext()
  const { accessRule: accessRuleObj } = useAccessRuleContext()

  const earlyAccessPerkId = accessRuleObj?.audienceTiers.find(tier => tier.earlyAccessPerkId)?.earlyAccessPerkId
  const exclusiveAccessPerkId = accessRuleObj?.exclusiveAccessPerkId
  const hasMultiplePerks = earlyAccessPerkId && exclusiveAccessPerkId

  const { data: exclusivePerk, isFetching: isFetchingExclusivePerk } = useGetPerkQuery(
    !hasMultiplePerks && exclusiveAccessPerkId && venueId ? { venueId, perkId: exclusiveAccessPerkId } : skipToken
  )
  const { data: earlyPerk, isFetching: isFetchingEarlyPerk } = useGetPerkQuery(
    !hasMultiplePerks && earlyAccessPerkId && venueId ? { venueId, perkId: earlyAccessPerkId } : skipToken
  )

  const goToPerk = useCallback(() => {
    if (exclusiveAccessPerkId && earlyAccessPerkId) {
      nav.push(routes.manager2.marketing.perks2, {
        params: { venueKey },
      })
    } else if (earlyAccessPerkId) {
      nav.push(routes.manager2.marketing.perks2.editEarlyAccess, {
        params: { venueKey, perkId: earlyAccessPerkId },
      })
    } else {
      nav.push(routes.manager2.marketing.perks2.editExclusive, {
        params: { venueKey, perkId: exclusiveAccessPerkId || '' },
      })
    }

    window.location.reload()
  }, [nav, venueKey, exclusiveAccessPerkId, earlyAccessPerkId])

  return (
    <ThemeProvider theme={vmsTheme}>
      <ReactComponentsBox mt={6} sx={{ backgroundColor: 'inherit' }}>
        {accessRule.guestDurationPicker.guestMustSpecifyDuration && (
          <Box pb="m">
            <DurationWidgetRequired />
          </Box>
        )}

        {(hasMultiplePerks || exclusivePerk || earlyPerk) && (
          <Box mb="m">
            <Banner
              title={formatMessage(accessRulesMessages.perkLinkTitle)}
              description={
                hasMultiplePerks
                  ? formatMessage(accessRulesMessages.multiplePerkLinkDescription)
                  : formatMessage(accessRulesMessages.perkLinkDescription, { name: (exclusivePerk || earlyPerk)?.internalName ?? '' })
              }
              action={
                hasMultiplePerks
                  ? formatMessage(accessRulesMessages.multiplePerkLinkAction)
                  : formatMessage(accessRulesMessages.perkLinkAction)
              }
              onAction={goToPerk}
              isLoading={isFetchingExclusivePerk || isFetchingEarlyPerk}
            />
          </Box>
        )}
        <Report>
          <ScheduleReport accessRule={accessRule} />
          <PartySizeReport accessRule={accessRule} />
          <SeatingAreasReport accessRule={accessRule} />
          <GuestFacingReport accessRule={accessRule} />
          <PaymentPolicyReport accessRule={accessRule} />
          <BookingsChannelsReport accessRule={accessRule} />
          <DurationsReport accessRule={accessRule} />
          <UpgradesReport accessRule={accessRule} />
          <ReservationTagsReport accessRule={accessRule} />
          <BookingWindowReport accessRule={accessRule} />
          <ReservationCoverLimitReport accessRule={accessRule} />
          <PacingReport accessRule={accessRule} />
          <GuestDurationPickerReport accessRule={accessRule} />
        </Report>
      </ReactComponentsBox>
    </ThemeProvider>
  )
}
