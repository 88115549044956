import type { Shift } from '@sevenrooms/core/domain'
import { getAccessRulesDisplayOrder, type SortableAccessRule } from './accessRulesCalendarUtils'

export function assignInternalDisplayColor(accessRules: SortableAccessRule[], shifts: Shift[], inProgressRule: SortableAccessRule): string {
  const rulesWithSortOrders = getAccessRulesDisplayOrder(accessRules.concat([inProgressRule]), shifts)

  const inProgressIndex = rulesWithSortOrders.findIndex(x => x.rule.id === inProgressRule.id)
  const neighbors = [
    rulesWithSortOrders.at(inProgressIndex - 2),
    rulesWithSortOrders.at(inProgressIndex - 1),
    rulesWithSortOrders.at(inProgressIndex + 1),
    rulesWithSortOrders.at(inProgressIndex + 2),
  ]

  const usedColors = new Set(accessRules.map(x => x.internalDisplayColor))

  const buckets = COLOR_PALETTE.filter(x =>
    neighbors.every(neighbor => !neighbor?.rule?.internalDisplayColor || !x.includes(neighbor.rule.internalDisplayColor))
  )

  const color = buckets
    .sort(() => Math.random() - 0.5)
    .flatMap(x => x)
    .filter(x => !usedColors.has(x))
    .at(0)

  if (color) {
    return color
  }

  return assignRandomInternalDisplayColor()
}

export function assignRandomInternalDisplayColor(): string {
  const bucket = COLOR_PALETTE[Math.floor(Math.random() * COLOR_PALETTE.length)] as string[]
  return bucket[Math.floor(Math.random() * bucket.length)] as string
}

const COLOR_PALETTE = [
  // Blues
  ['#E5F2FA', '#DCF6FF', '#C8E3F4', '#BDEAFB', '#A8DFF7', '#92D3F3', '#BBDEFB', '#90CAF9', '#64B5F6', '#2196F3'],
  // Greens
  ['#E9F6ED', '#DFF0E5', '#C8E6C9', '#C2DFC9', '#BDE6CE', '#ADDAAF', '#A5D6A7', '#81C784', '#4CAF50', '#43A047'],
  // Yellows
  ['#FFF6E1', '#FFF2D4', '#FFEBB8', '#FFEB9E', '#FFF4C2', '#FFED95', '#FFF59D', '#FFEB3B', '#FDD835', '#FDD835'],
  // Purple
  ['#EAE5F8', '#DAD3FF', '#D7D4EA', '#C1B5FF', '#BBACE9', '#C6A0FF', '#A590FF', '#9C8FD5', '#967EFF', '#B786FF'],
  // Oranges
  ['#FFEEDC', '#FFE4C4', '#FFDFB8', '#FFD0A2', '#FFC78E', '#FFB97A', '#FFCC80', '#FFA726', '#FB8C00'],
  // Pinks
  ['#F9E5EB', '#F3D7E0', '#FFD2E1', '#E9C0D0', '#F8BBD0', '#E59EC0', '#F48FB1', '#F06292', '#EC407A'],
  // Aqua
  ['#A8E8BF', '#ABE6D4', '#B5F4E1', '#B2F7CA', '#96FBDD', '#86EDCE', '#ABE8D5', '#0EDB9D', '#97CEBB', '#40BD97'],
]
