import { useLocales } from '@sevenrooms/core/locales'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { blueGrey } from '@sevenrooms/react-components'
import { Box } from '@sevenrooms/react-components/components/Box'
import { accessRulesMessages } from '../../AccessRules.locales'

interface TimeSlotChipProps {
  timeSlot?: string
}

interface TimeRangeChipProps {
  timeInterval?: string
}

function TimeSlotChip({ timeSlot }: TimeSlotChipProps) {
  if (!timeSlot) {
    return null
  }

  return (
    <Box
      sx={{
        height: 22,
        width: 60,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        px: 1,
        py: 1,
        borderRadius: '4px',
        backgroundColor: blueGrey[200],
      }}
    >
      <Text>{timeSlot}</Text>
    </Box>
  )
}

function TimeRangeChip({ timeInterval }: TimeRangeChipProps) {
  if (!timeInterval) {
    return null
  }

  return (
    <Box
      sx={{
        height: 22,
        width: 140,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        px: 1,
        py: 1,
        borderRadius: '4px',
        backgroundColor: blueGrey[200],
      }}
    >
      <Text>{timeInterval}</Text>
    </Box>
  )
}

function DefaultTimeChip() {
  const { formatMessage } = useLocales()
  return (
    <Box
      sx={{
        height: 22,
        width: 60,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        px: 1,
        py: 1,
        borderRadius: '4px',
        backgroundColor: blueGrey[50],
      }}
    >
      <Text>{formatMessage(accessRulesMessages.default)}</Text>
    </Box>
  )
}

export { TimeRangeChip, TimeSlotChip, DefaultTimeChip }
