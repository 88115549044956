import { useWatch } from '@sevenrooms/core/form'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { getHoursAndMinutes } from '@sevenrooms/core/timepiece'
import { Pair, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { DurationWidgetRequired } from '../../DurationWidgetRequired'
import { GuestDurationPickerLocales } from '../GuestDurationPicker.locales'
import type { GuestDurationPickerFieldProps } from './GuestDurationPickerFields'

export function CollapsedGuestDurationPicker({ field }: GuestDurationPickerFieldProps) {
  const { formatMessage } = useLocales()
  const { guestMustSpecifyDuration, durationMin, durationMax } = useWatch(field)

  return (
    <Text fontSize="m">
      <VStack spacing="m">
        <VStack spacing="s">
          <Pair
            left={formatMessage(GuestDurationPickerLocales.specifyDurationLabelCollapsed)}
            right={guestMustSpecifyDuration ? formatMessage(commonMessages.yes) : formatMessage(commonMessages.no)}
          />
          {guestMustSpecifyDuration && (
            <>
              {durationMin && (
                <Pair
                  left={formatMessage(GuestDurationPickerLocales.durationMinCollapsed)}
                  right={formatMessage(GuestDurationPickerLocales.durationTimeFormat, getHoursAndMinutes(durationMin))}
                />
              )}
              {durationMax && (
                <Pair
                  left={formatMessage(GuestDurationPickerLocales.durationMaxCollapsed)}
                  right={formatMessage(GuestDurationPickerLocales.durationTimeFormat, getHoursAndMinutes(durationMax))}
                />
              )}
            </>
          )}
        </VStack>
        {guestMustSpecifyDuration && <DurationWidgetRequired />}
      </VStack>
    </Text>
  )
}
