import type { TaxRate } from '@sevenrooms/core/domain'
import type { ChargeSettingsForm } from './chargeSettingsForm'

export interface ChargeParams {
  defaultServiceCharge: number
  defaultGratuity: number
  chargeDetails: Partial<ChargeSettingsForm>
  taxRates?: TaxRate[]
}

export interface ChargeRates {
  serviceChargeRate: number
  taxRate: number
  gratuityRate: number
}

export function applyCharges(subTotal: number, rates: ChargeRates): number {
  let total = subTotal + subTotal * rates.serviceChargeRate
  total += total * rates.taxRate
  total += subTotal * rates.gratuityRate
  return total
}
