import { defineMessages } from '@sevenrooms/core/locales'

export const SeatingAreasLocales = defineMessages({
  title: {
    id: 'ar.seatingAreas.title',
    defaultMessage: 'Seating Areas and Tables',
  },
  description: {
    id: 'ar.seatingAreas.description',
    defaultMessage: 'Ex: Limit reservations to your Patio only.',
  },
  subCaption: {
    id: 'ar.seatingAreas.subCaption',
    defaultMessage: 'Limit reservations to specific seating areas.',
  },
  seatingAreasLabel: {
    id: 'ar.seatingAreas.seatingAreasLabel',
    defaultMessage: 'Seating Areas / Tables:',
  },
  multiSelectLabel: {
    id: 'ar.seatingAreas.multiSelectLabel',
    defaultMessage: 'Seating Areas and Tables',
  },
  multiSelectPlaceholder: {
    id: 'ar.seatingAreas.multiSelectPlaceholder',
    defaultMessage: 'Search Seating Areas and Tables',
  },
  checkboxLabel: {
    id: 'ar.seatingAreas.checkboxLabel',
    defaultMessage: 'Restrict these Seating Areas and Tables from being booked outside of this Access Rule',
  },
  treatAsBlockedTooltip: {
    id: 'ar.seatingAreas.treatAsBlockedTooltip',
    defaultMessage:
      'This setting ensures the selected Seating Areas, Tables or Table Combinations are only bookable through this Access Rule. Once the Access Rule Booking Cutoff Time passes, Reservations can be assigned to these Seating Areas, Tables and Table Combinations. Superusers and Managers can override this setting. <a>Learn more.</a>',
  },
  conflictTooltip: {
    id: 'ar.seatingAreas.conflictTooltip',
    defaultMessage:
      'Please note: Guests will not be able to book this Seating Area or Table on one or more Shifts because the Seating Area is not assigned to those Shifts.',
  },
  conflictModalHeading: {
    id: 'ar.seatingAreas.conflictModalHeading',
    defaultMessage: 'Shift settings will limit availability for this Access Rule.',
  },
  conflictModalSubtext: {
    id: 'ar.seatingAreas.conflictModalSubtext',
    defaultMessage: 'A Table or Seating Area cannot be booked if it is not included in the Shift.',
  },
  conflictModalSetting: {
    id: 'ar.seatingAreas.conflictModalSetting',
    defaultMessage: 'Access Rule Setting:',
  },
  conflictModalExplanation: {
    id: 'ar.seatingAreas.conflictModalExplanation',
    defaultMessage:
      'To make Not Bookable Seating Areas available for online reservations, save and edit the Access Rule. Then add the Seating Area to the Shift.',
  },
  conflictModalShiftSettings: {
    id: 'ar.seatingAreas.conflictModalShiftSettings',
    defaultMessage: 'Shift Settings',
  },
  conflictModalResults: {
    id: 'ar.seatingAreas.conflictModalResults',
    defaultMessage: 'Resulting Availability',
  },
  conflictModalBookable: {
    id: 'ar.seatingAreas.conflictModalBookable',
    defaultMessage: 'Bookable',
  },
  conflictModalNotBookable: {
    id: 'ar.seatingAreas.conflictModalNotBookable',
    defaultMessage: 'Not Bookable',
  },
  modalShift: {
    id: 'ar.seatingAreas.modalShift',
    defaultMessage: 'Shift',
  },
  modalSeatingAreas: {
    id: 'ar.seatingAreas.modalSeatingAreas',
    defaultMessage: 'Seating Areas',
  },
  moreAboutThisSetting: {
    id: 'ar.seatingAreas.moreAboutThisSetting',
    defaultMessage: 'More about this setting',
  },
  googleReserveLabel: {
    id: 'ar.seatingAreas.googleReserveLabel',
    defaultMessage: 'Google Reserve Seating Area',
  },
  googleReserveCollapsedLabel: {
    id: 'ar.seatingAreas.googleReserveCollapsedLabel',
    defaultMessage: 'Google Reserve Seating Area:',
  },
  theForkLabel: {
    id: 'ar.seatingAreas.theForkLabel',
    defaultMessage: 'TheFork Area Type',
  },
  theForkCollapsedLabel: {
    id: 'ar.seatingAreas.theForkCollapsedLabel',
    defaultMessage: 'TheFork Area Type:',
  },
  allSeatingAreas: {
    id: 'ar.seatingAreas.allSeatingAreas',
    defaultMessage: 'All Seating Areas',
  },
  treatTablesAsBlocked: {
    id: 'ar.seatingAreas.treatTablesAsBlocked',
    defaultMessage: 'Restrict these Seating Areas and Tables from being booked outside of this Access Rule:',
  },
  reportTreatTablesAsBlocked: {
    id: 'ar.seatingAreas.reportTreatTablesAsBlocked',
    defaultMessage: 'Restrict these Seating Areas and Tables from being booked outside of this Access Rule',
  },
  helpTooltipImportant: {
    id: 'ar.seatingAreas.helpTooltipImportant',
    defaultMessage: 'Important:',
  },
  helpTooltipSingleSeatingArea: {
    id: 'ar.seatingAreas.helpTooltipSingleSeatingArea',
    defaultMessage:
      'If <strong>1 Table/Seating Area selected</strong>: These reservations will <strong>always</strong> be assigned to the selected Seating Area/Table. Manual assignments or overbookings will lead to unassigned reservations.',
  },
  helpTooltipMultipleSeatingArea: {
    id: 'ar.seatingAreas.helpTooltipMultipleSeatingArea',
    defaultMessage:
      'If <strong>2+ Tables/Seating Areas selected</strong>: These reservations will <strong>attempt</strong> to be assigned to the selected Seating Areas/Tables. In the case of manual assignments or overbookings, these reservations may be assigned to another open table not included in this rule.',
  },
  shiftSettingsSeatingAreas: {
    id: 'ar.edit.shiftSettingsSeatingAreas',
    defaultMessage: 'Shift Settings - Seating Areas',
  },
} as const)
