import type { AccessRuleTimeUnitType, Shift } from '@sevenrooms/core/domain'
import { TimeOnly } from '@sevenrooms/core/timepiece'
import type { SelectOption } from '@sevenrooms/core/ui-kit/core'
import type { TagOption } from '@sevenrooms/core/ui-kit/form'
import { type TimeBeforeForm, timeBeforeToHours } from './timeBeforeForm'
import type { TagGroup } from '../../AccessRule.types'

export interface TimeSegment {
  start: Date
  end: Date
}

export interface TimeSlot {
  hours: string
  mins: string
  label: string
}

export function isTimeSegment(segment: Partial<TimeSegment>): segment is TimeSegment {
  return !!segment.end && !!segment.start
}

// Could be generalized further and exported in some util lib
// seems like a function lodash should have, though...
export function cartesianProduct<T, U>(arr1: T[], arr2: U[]) {
  return arr1.flatMap(a1 => arr2.map(a2 => [a1, a2] as const))
}

export function getMinInterval(shifts: Shift[]): number {
  return Math.min(...shifts.map(({ intervalMinutes }) => intervalMinutes), 60)
}

export function generateTimeSlotsBase(): TimeSlot[] {
  const interval = 15
  const hours = [...Array(24).keys()]
  const minutes = [...Array(60 / interval).keys()].map(idx => idx * interval)
  return cartesianProduct(hours, minutes)
    .map(([hr, mins]) => [String(hr).padStart(2, '0'), String(mins).padStart(2, '0')] as const)
    .map(([hr, mins]) => ({
      hours: hr,
      mins,
      label: TimeOnly.from(`${hr}:${mins}`).formatSTime(),
    }))
}

export function generateOrderedTimeSlots(startOfDayTime: string): TimeSlot[] {
  const timeSlots = generateTimeSlotsBase()
  const startTime = TimeOnly.fromSafe(startOfDayTime)?.getInfo()
  const dayStartIndex = timeSlots.findIndex(
    ({ hours, mins }) => parseInt(hours) === startTime?.hours && parseInt(mins) === startTime.minutes
  )

  return dayStartIndex !== -1 ? timeSlots.slice(dayStartIndex).concat(timeSlots.slice(0, dayStartIndex)) : timeSlots
}

export function generateTimeSlots(startOfDayTime: string): SelectOption[] {
  return generateOrderedTimeSlots(startOfDayTime).map(({ hours, mins, label }) => ({
    id: `${hours}:${mins}:00`,
    label,
  }))
}

export function getAccessTimeUpperBound(unit: AccessRuleTimeUnitType): number {
  switch (unit) {
    case 'MONTHS':
      return 24
    case 'WEEKS':
      return 104
    case 'DAYS':
      return 730
    case 'HOURS':
      return 100
    case 'MINUTES':
    default:
      return 6000
  }
}

export function checkTimeBeforeUpperBound(timeBefore: TimeBeforeForm): boolean {
  if (timeBefore.count === null) {
    return false
  } // responsibility of another validator
  return timeBefore.count > getAccessTimeUpperBound(timeBefore.unit)
}

export function checkTimeBeforeError(startTime: TimeBeforeForm, cutoffTime: TimeBeforeForm) {
  const startTimeHours = timeBeforeToHours(startTime)
  const cutoffTimeHours = timeBeforeToHours(cutoffTime)
  if (startTimeHours === null || cutoffTimeHours === null) {
    return false
  }

  return startTimeHours - cutoffTimeHours <= -24
}

export function checkTimeBeforeWarning(startTime: TimeBeforeForm, cutoffTime: TimeBeforeForm) {
  const startTimeHours = timeBeforeToHours(startTime)
  const cutoffTimeHours = timeBeforeToHours(cutoffTime)
  if (startTimeHours === null || cutoffTimeHours === null) {
    return false
  }

  return startTimeHours - cutoffTimeHours <= 48 && !checkTimeBeforeError(startTime, cutoffTime)
}

export function calculateTotalMinutes(hr: string, mins: string) {
  return String(Number(hr) * 60 + Number(mins))
}

export function getTagDisplayName(tag: TagOption, tagGroups: Map<string, TagGroup>): string {
  return tagGroups.get(tag.categoryId)?.tagNameDisplays[tag.label] ?? tag.label
}

export function getTagBackgroundColor(tag: TagOption, tagGroups: Map<string, TagGroup>) {
  return tagGroups.get(tag.categoryId)?.colorHex
}
