import { defineMessages } from '@sevenrooms/core/locales'

export const UpgradesLocales = defineMessages({
  title: {
    id: 'ar.upgrades.title',
    defaultMessage: 'Selectable Upgrades',
  },
  description: {
    id: 'ar.upgrades.description',
    defaultMessage: 'Ex: Allow your guests to select and pay for a wine pairing at the time of booking. <a>How to configure upgrades.</a>',
  },
  subCaption: {
    id: 'ar.upgrades.subCaption',
    defaultMessage: 'Allow your guests to add and pay for additional experiences or products when booking.',
  },
  collapsedContentLabel: {
    id: 'ar.upgrades.collapsedContentLabel',
    defaultMessage: 'Upgrade Categories:',
  },
  reportLabel: {
    id: 'ar.upgrades.reportLabel',
    defaultMessage: 'Upgrade Categories',
  },
  excludedRadioLabel: {
    id: 'ar.upgrades.excludedRadioLabel',
    defaultMessage: 'Do not include Selectable Upgrades',
  },
  includedRadioLabel: {
    id: 'ar.upgrades.includedRadioLabel',
    defaultMessage: 'Include Selectable Upgrades',
  },
  includedRadioDescription: {
    id: 'ar.upgrades.includedRadioDescription',
    defaultMessage: 'Guests will be presented with the option to choose and pay for Selectable Upgrades during checkout.',
  },
  multiSelectPlaceholder: {
    id: 'ar.upgrades.multiSelectPlaceholder',
    defaultMessage: 'Search Upgrade(s)',
  },
  noUpgradesError: {
    id: 'ar.upgrades.noUpgradesError',
    defaultMessage: 'Select at least one upgrade category',
  },
  asterisk: {
    id: 'ar.upgrades.asterisk',
    defaultMessage: '*To include <strong>Bundled Upgrades</strong>, configure in the Payment and Policy section.',
  },
  tooltipHeading: {
    id: 'ar.upgrades.tooltipHeading',
    defaultMessage: 'What are Bundled Upgrades?',
  },
  tooltipContent: {
    id: 'ar.upgrades.tooltipContent',
    defaultMessage:
      'Bundled Upgrades are automatically included with the reservation. Selectable Upgrades give guests the option to choose from a set of upgrades, and they may be required or optional. Guests pay for upgrades at the time of booking.',
  },
  setupPayments: {
    id: 'ar.view.setupPayments',
    defaultMessage: '<a>Set up payment functionality</a> to include paid upgrades at checkout.',
  },
  missingPaymentError: {
    id: 'ar.upgrades.missingPaymentError',
    defaultMessage:
      'One or more upgrades chosen require payment at checkout. Please set up a payment gateway before adding a paid upgrade.',
  },
  modalUpgrades: {
    id: 'ar.upgrades.modalUpgrades',
    defaultMessage: 'Upgrade Categories',
  },
  shiftSettingsUpgrades: {
    id: 'ar.edit.shiftSettingsUpgrades',
    defaultMessage: 'Shift Settings - Upgrades',
  },
} as const)
